// import FeedIndex from "./pages/Feed/Index";
import CharacterView from "@/pages/Character/View";
import CharacterEdit from "@/pages/Character/Edit";
import UserSignup from "@/pages/User/Signup";
import UserLogin from "@/pages/User/Login";
import PasswordReset from "@/pages/User/PasswordReset";
import PasswordRecovery from "@/pages/User/PasswordRecovery";
import PaymentHistory from "@/pages/User/PaymentHistory";
import UserPlan from "@/pages/User/Plan";
import UserPlanComplete from "@/pages/User/PlanComplete";
import UserPlanFailed from "@/pages/User/PlanFailed";
import CharacterNew from "@/pages/Character/New";
import CharacterIndex from "@/pages/Character/Index";
import FAQPage from "@/pages/common/FAQ";
import Terms from "@/pages/common/Terms";
import TermList from "@/pages/common/TermList";
import HelpList from "@/pages/common/HelpList";
import Policy from "@/pages/common/Policy";
import Law from "@/pages/common/Law";
import Contact from "@/pages/common/Contact";
import MyPage from "@/pages/MyPage/Index";
import Videos from "@/pages/Videos/Index";
import HomeIndex from "@/pages/Home/Index";
import VerifyIndex from "@/pages/Verify/Index";
import EmailVerifyIndex from "@/pages/EmailVerify/Index";
import NoticeIndex from "@/pages/Notice/Index";
import NoticeView from "@/pages/Notice/View";
import { isAdult } from "@/constants";
import DemoIndex from "@/pages/Demo/Demo.tsx";
import LineSignup from "@/pages/User/LineSignup.tsx";
import LineLogin from "@/pages/User/LineLogin.tsx";
import GuestScene from "@/pages/GuestScene";
import XSignup from "@/pages/User/XSignup.tsx";
import XLogin from "@/pages/User/XLogin.tsx";
import EmailRegistration from "@/pages/User/EmailRegistration.tsx";

type routeType = {
  path: string;
  component: (React.FC) | (() => JSX.Element);
  login_required?: boolean;
  title?: string;
};

export const routes: routeType[] = [
  {
    path: "/",
    component: HomeIndex,
    login_required: true,
    title: 'Home',
  },
  {
    path: "/users/signup",
    component: UserSignup,
    title: 'Signup',
  },
  {
    path: "/users/login",
    component: UserLogin,
    title: 'Login',
  },
  {
    path: "/users/password-reset",
    component: PasswordReset,
    title: 'Password Reset',
  },
  {
    path: "/users/password-recovery",
    component: PasswordRecovery,
    title: 'Password Recovery',
  },
  {
    path: "/users/email-registration",
    component: EmailRegistration,
    title: 'Password Reset',
  },
  {
    path: "/verify",
    component: VerifyIndex,
    title: 'Verify',
  },
  {
    path: "/verify/email",
    component: EmailVerifyIndex,
    title: 'Email Verify',
  },
  {
    path: "/notice",
    component: NoticeIndex,
    login_required: true,
    title: 'Notice',
  },
  {
    path: "/notice/:noticeId",
    component: NoticeView,
    login_required: true,
    title: 'Notice',
  },
  {
    path: "/users/payment-history",
    component: PaymentHistory,
    login_required: true,
    title: 'Payment History',
  },
  {
    path: "/users/plan",
    component: UserPlan,
    login_required: true,
    title: 'Plan',
  },
  {
    path: "/users/plan/complete",
    component: UserPlanComplete,
    login_required: true,
    title: 'Plan Complete',
  },
  {
    path: "/users/plan/failed",
    component: UserPlanFailed,
    login_required: true,
    title: 'Plan Failed',
  },
  {
    path: "/characters/new",
    component: CharacterNew,
    login_required: true,
    title: 'Character New',
  },
  {
    path: "/characters",
    component: CharacterIndex,
    login_required: true,
    title: 'Characters',
  },
  {
    path: "/characters/:characterId",
    component: CharacterView,
    login_required: true,
    title: 'Character',
  },
  {
    path: "/characters/:characterId/edit",
    component: CharacterEdit,
    login_required: true,
    title: 'Character Edit',
  },
  {
    path: "/term",
    component: TermList,
    title: 'Term',
  },
  {
    path: "/help",
    component: HelpList,
    title: 'help',
  },
  {

    path: "/faq",
    component: FAQPage,
    title: 'Faq',
  },
  {
    path: "/terms",
    component: Terms,
    title: 'Term',
  },
  {
    path: "/policy",
    component: Policy,
    title: 'Policy',
  },
  {
    path: "/law",
    component: Law,
    title: 'Law',
  },
  {
    path: "/contact",
    component: Contact,
    title: 'Contact',
  },
  {
    path: "/mypage",
    component: MyPage,
    login_required: true,
    title: 'Mypage',
  },
  {
    path: "/videos",
    component: Videos,
    login_required: true,
    title: 'Videos',
  },
  {
    path: "/guest/scenes",
    component: GuestScene,
    title: 'Scenes',
  },
  {
    path: "/line/callback/signup",
    component: LineSignup,
    login_required: false,
    title: 'Line Signup',
  },
  {
    path: "/line/callback/login",
    component: LineLogin,
    login_required: false,
    title: 'Line Login',
  },
  {
    path: "/x/callback/signup",
    component: XSignup,
    login_required: false,
    title: 'X Signup',
  },
  {
    path: "/x/callback/login",
    component: XLogin,
    login_required: false,
    title: 'X Login',
  },
];

if (isAdult) {
  const adultRoutes: routeType[] = [
    {
      path: "/demo",
      component: DemoIndex,
      title: 'Demo',
    },
  ]

  routes.push(...adultRoutes);
}
