import { Stack, Box, Typography } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SwapIcon from "@/assets/SwapIcon2.svg";
import { FormattedMessage } from "react-intl";

export default function SwitchHint() {
  return (
    <Stack
      position="absolute"
      padding="14px 24px"
      spacing={1}
      width="300px"
      sx={{
        backgroundColor: '#FFF',
        top: '-118px',
        left: '-120px',
        borderRadius: '16px 16px 0px 16px',
      }}
    >
      <Typography
        variant="body1"
        color="rgba(0, 0, 0, 0.60)"
        sx={{
          fontSize: '10px',
          lineHeight: 1.5,
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <TipsAndUpdatesIcon fontSize="small" sx={{ color: "#FFD54F" }} />
        <FormattedMessage id="hint.switch.how_to_use" />
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
        }}
      >
        <FormattedMessage id="hint.switch.lets" />
      </Typography>

      <Stack
        direction="row"
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="start-flex"
      >
        <Box
          display="flex"
          gap="4px"
          alignItems="center"
          sx={{
            borderRadius: '4px',
            border: '1px solid #4CAF50',
            padding: '1px 2px',
          }}
        >
          <img src={SwapIcon} alt="swap icon" width="14px" />
          <Typography variant="caption" color="#4CAF50" >
            <FormattedMessage id="free" />
          </Typography>
        </Box>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.60)" >
          <FormattedMessage id="hint.switch.caption" />
        </Typography>
      </Stack>
    </Stack>
  )
}