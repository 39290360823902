import { SwappedImageHistory } from "@/services/models"
import { getRelativeTime } from "@/utils";
import { Avatar, Box, Button, Card, CardHeader, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SwapIcon from "@/assets/SwapIcon.svg";
import { color, mainTheme } from "@/services/themes";
import { FormattedMessage } from "react-intl";
import { openSwitchImageDialog } from "@/services/actions/commonActions";
import { useDispatch } from "@/services/hooks";

type Props = {
  scene_image_history: SwappedImageHistory
}

export default function SceneImage(props: Props) {
  const dispatch = useDispatch();
  const history = props.scene_image_history;

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative',
        }}
      >
        <CardHeader
          sx={{ padding: "8px 0" }}
          avatar={
            (history.character?.is_editable && !history.character?.is_limited) ?
              <Link to={`/characters/${history.character.id}`}>
                <Avatar src={history.character.url} />
              </Link> :
              <div>
                <Avatar src={history.character?.url} />
              </div>
          }
          title={history.character?.name}
          {...{ subheader: getRelativeTime(history.created_at) }}
          disabled={!history.character?.is_editable}
        />

        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          {history.original_scene.title}
        </Typography>

        <Box mt="12px">
          <img style={{ width: '100%' }} src={history.s3_urls[0]} />
        </Box>

        <Box
          mt="12px"
          display="flex"
          justifyContent="end"
        >

          <Button
            onClick={() => {
              dispatch(openSwitchImageDialog(history));
            }}
            size="small"
            color="inherit"
            sx={{
              zIndex: 1,
              bgcolor: color.sceneEnjoyButton,
              color: '#fff', "&:hover": { bgcolor: mainTheme.palette.primary.main },
              postion: 'relative',
              height: '30px',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            startIcon={<img
              style={{
                width: '18px',
                display: 'inline-block',
                marginLeft: '8px',
              }}
              src={SwapIcon}
              alt="swap icon"
            />}
          >
            <FormattedMessage id="scene_image.enjoy" />
          </Button>
        </Box>

      </Card>

      <Divider sx={{ mt: 2 }} />
    </>
  )
}