import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import {
  useSelector
} from '@/services/hooks';
import { FormattedMessage } from 'react-intl';

const MaintenanceDialog = () => {
  const { is_maintenance } = useSelector(state => state.common);

  return (
    <div>
      <Dialog
        open={is_maintenance}
      >
        <DialogTitle>
          <Typography variant="subtitle1">
            <FormattedMessage id="maintenance.title" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="maintenance-dialog-description">
            <Typography variant="body1">
              <FormattedMessage id="maintenance.description_long" />
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MaintenanceDialog;