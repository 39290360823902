import { Box, BoxProps } from "@mui/material";
import { useLayoutEffect, useState } from "react";

type ConditionalDisplayProps = BoxProps & {
  isShow: boolean;
  hideDelay?: number; // 非表示になるまでの遅延時間 (ms) デフォルトは0 アニメーションが終了してから非表示にする場合などに使う
}

/**
 * isShow が true のときだけ表示するコンポーネント
 * isShow が false のときは display: none になる
 * 初めてtrueになるまではchildrenをレンダリングしない
 * 一度でもtrueになったらfalseになってもレンダリングは行う
 */
export default function ConditionalDisplay({ isShow, hideDelay, ...props }: ConditionalDisplayProps) {
  const [hasBeenShown, setHasBeenShown] = useState(isShow) // 一度でもisShowがtrueになったかどうか
  const [isHideDelayed, setIsHideDelayed] = useState(false) // 非表示の遅延が終了したかどうか
  useLayoutEffect(() => {
    if (isShow) {
      setIsHideDelayed(false);
      setHasBeenShown(true);
      return;
    }
    if (!isHideDelayed) {
      setTimeout(() => {
        setIsHideDelayed(true)
      }, hideDelay);
    }
  }, [isShow]);

  if (!hasBeenShown) {
    return null;
  }
  return <Box {...props} sx={{
    ...props.sx,
    ...(!isShow && isHideDelayed ? { display: 'none' } : {})
  }}>
    {props.children}
  </Box>
}
