// Terms.tsx
import React, { useEffect, useState } from "react";

import { Typography, Stack } from "@mui/material";

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
import { getLocale } from "@/utils";

const Terms: React.FC = () => {
  const [termsText, setTermsText] = useState("");

  useEffect(() => {
    const locale = getLocale();
    // テキストファイルをフェッチして内容を読み込む
    fetch(`/terms_${locale}.txt`)
      .then((response) => response.text())
      .then((text) => setTermsText(text));
  }, []);

  return (
    <AppBar title={<FormattedMessage id="terms.title" />}>
      <Stack padding={2}>
        {/* <Typography variant="h4" gutterBottom>
          ご利用規約
        </Typography> */}
        <Typography variant="body1" paragraph style={{ whiteSpace: "pre-line" }}>
          {termsText}
        </Typography>
      </Stack>
    </AppBar>
  );
};

export default Terms;
