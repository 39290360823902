import React, { useEffect, useState } from "react";
import _ from 'lodash';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { Purchase } from "@/services/models";
import { loadUTC } from "@/utils";
import { FormattedMessage } from "react-intl";

export const PaymentHistory: React.FC = () => {
  const [purchaseHistories, setPurchaseHistories] = useState<Purchase[]>([]);

  useEffect(() => {
    ApiService.fetchPurchaseHistory().then((data) => {
      setPurchaseHistories(data);
    });
  }, [])

  return (
    <AppBar title={<FormattedMessage id="user.purchase.history.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          {
            _.isEmpty(purchaseHistories) && (
              <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <ListItemButton>
                  <ListItemText primary={<FormattedMessage id="user.purchase.history.empty" />} />
                </ListItemButton>
              </ListItem>
            )
          }
          {_.map(purchaseHistories, (purchase) => {
            console.log(purchase);
            const priceStr = purchase.amount_yen ? `¥${purchase.amount_yen}` : `$${purchase.amount_dollar}`;
            const date = loadUTC(purchase.created_at).format('YYYY/MM/DD hh:mm:ss');
            return (
              <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <ListItemButton>
                  <ListItemText
                    primary={purchase.name}
                    secondary={date}

                  />
                  <Typography variant="body2" color="primary">{priceStr}</Typography>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </div>
    </AppBar>
  );
};

export default PaymentHistory;
