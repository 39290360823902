import { Link } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import { closeRegistrationPopup } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { drawerWidth } from "@/constants";
import { FormattedMessage } from "react-intl";

export default function RegistrationPopup() {
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(closeRegistrationPopup());
  };

  const { registration_popup } = useSelector((state: any) => state.common);
  return (
    <Dialog
      onClose={closeDialog}
      open={registration_popup}
      sx={{
        "& .MuiDialog-paper": {
          width: '100%',
        },
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          sm: { marginLeft: `${drawerWidth}px` }
        }
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '16px',
          lineHeight: 'normal',
          letterSpacing: '0.15px',
        }}
      >
        <FormattedMessage id="registration_dialog.message" />
      </DialogTitle>
      <DialogContent
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          paddingBottom: 0,
        }}
      >
        <Link to="/users/signup" onClick={closeDialog}>
          <Button fullWidth variant="contained"><FormattedMessage id="registration_dialog.registration" /></Button>
        </Link>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}><FormattedMessage id="registration_dialog.close" /></Button>
      </DialogActions>
    </Dialog>
  );
}