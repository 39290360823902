import React, { ReactElement, useEffect, useState } from "react";

import {
  FormControl,
  Stack,
  TextField,
  Button,
  Typography,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { FormattedMessage } from "react-intl";
import { openSnackbar } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';

export default function PasswordReset() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | ReactElement>("");
  const [serverError, setServerError] = useState<string | ReactElement>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSend, setIsSend] = useState(false);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [user])

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setError(validateEmail(value))
  };

  const validateEmail = (email: string): string | ReactElement => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      return <FormattedMessage id="common.invalid.email_format" />;
    }
    return "";
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    console.log("send reset password email to: ", email);
    // メールアドレスが無効な場合にエラーを表示するか？
    setIsSend(true);
    ApiService.reset(email)
      .then(() => {
        dispatch(openSnackbar(<FormattedMessage id="user.login.resend_password_mail" />));
      })
      .catch(() => setServerError(<FormattedMessage id="common.invalid.email" />));
    setIsSubmitting(false);

  };

  const isFormValid =
    email && !error;

  return (
    <AppBar title={<FormattedMessage id="user.password_reset.title" />}>
      <Stack spacing={2} marginY={3} marginX={2} >
        <Typography variant="body1"><FormattedMessage id="user.password_reset.description" /></Typography>
        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            label={<FormattedMessage id="user.password_reset.mail" />}
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              readOnly: !!user?.email,
            }}
          />
        </FormControl>

        {serverError && <p style={{ color: "#d32f2f" }}>{serverError}</p>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid || isSubmitting || isSend}
        >
          <FormattedMessage id="user.password_reset.submit" />
        </Button>
      </Stack>
    </AppBar >
  );
}

