import { Typography, Stack, Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

const AboutSwitchedScene = () => {
  return (
    <Stack
      gap={2}
      padding="20px 16px"
      width="100%"
      border="1px solid rgba(0, 0, 0, 0.12)"
      sx={{ backgroundColor: 'rgba(242, 242, 242, 1)' }}
    >
      <Typography variant="subtitle1" color="primary" sx={{ textAlign: 'center' }}>
        <FormattedMessage id="user.plan.about_switched_scene_streaming.title" />
      </Typography>

      <Typography variant="caption" sx={{ fontSize: "13px" }}>
        <Stack gap={1}>
          <Stack direction="row" gap={0.5}>
            ※
            <Box>
              <FormattedMessage id="user.plan.about_switched_scene_streaming.description1" values={{
                bold1: <strong><FormattedMessage id="user.plan.about_switched_scene_streaming.description1.bold1" /></strong>,
                bold2: <strong><FormattedMessage id="user.plan.about_switched_scene_streaming.description1.bold2" /></strong>,
              }} />
            </Box>
          </Stack>
          <Stack direction="row" gap={0.5}>
            ※
            <Box>
              <FormattedMessage id="user.plan.about_switched_scene_streaming.description2" values={{
                bold1: <strong><FormattedMessage id="user.plan.about_switched_scene_streaming.description2.bold1" /></strong>,
                bold2: <strong><FormattedMessage id="user.plan.about_switched_scene_streaming.description2.bold2" /></strong>,
              }} />
            </Box>
          </Stack>
        </Stack>
      </Typography>
    </Stack>
  )
}

export default AboutSwitchedScene;