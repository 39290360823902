import AppBar from "@/components/AppBar";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import QuizIcon from "@mui/icons-material/QuizOutlined";
import MailIcon from "@mui/icons-material/MailOutlined";
import { useNavigateWithQueryAndHash } from "@/services/hooks";
import { trackingUrlParam } from "@/utils";

const menu = [
  {
    text: <FormattedMessage id="sidebar.contact" />,
    icon: <MailIcon color="primary" />,
    link: "/contact",
  },
  {
    text: <FormattedMessage id="sidebar.faq" />,
    icon: <QuizIcon color="primary" />,
    link: "/faq",
  },
];

const HelpList: React.FC = () => {
  const navigateTo = useNavigateWithQueryAndHash();

  return (
    <AppBar title={<FormattedMessage id="help.title" />}>
      <Stack padding={2}>
        <List>
          {menu.map((item) => {
            return (
              <ListItem
                key={item.link}
                disablePadding
                sx={{
                  border: 'solid 1px #0000001F',
                  marginTop: '-1px',
                }}
                onClick={() => {
                  navigateTo(item.link);
                }}
              >
                <ListItemButton
                  sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                >
                  <Box display="flex" alignItems="center">
                    <ListItemIcon sx={{ minWidth: '40px' }}>{item.icon}</ListItemIcon>
                    <ListItemText sx={{ marginRight: '2px' }} primary={item.text} />
                  </Box>
                </ListItemButton>
              </ListItem>
            )
          })}

          <Link
            to={`/term${trackingUrlParam()}`}
            style={{
              marginTop: '24px',
              display: 'block',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1">
              <FormattedMessage id="help.link_term" />
            </Typography>
          </Link>
        </List>
      </Stack>
    </AppBar>
  );
};

export default HelpList;