import AppBar from "@/components/AppBar";
import SceneDummyComp from "@/components/Scene/dummy";
import AgeConfirm from "@/pages/Demo/AgeConfirm";
import GenreSearch from "@/pages/Home/GenreSearch";
import { ApiService } from "@/services/api";
import { useUrlParams } from "@/services/hooks";
import { Scene } from "@/services/models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const LIMIT = 10;

const GuestScene: React.FC = () => {
  const navigateTo = useNavigate();
  const { getUrlParam } = useUrlParams();
  const sort = getUrlParam('sort') || 'recommend';
  const filter = getUrlParam('filter');

  const [scenes, setScenes] = useState<Scene[]>([]);
  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);

  const [isOpen, setIsOpen] = useState(false);

  const fetchScenes = useCallback(() => {
    const option = {
      filter,
      sort,
      page: 0
    };

    ApiService.getScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setScenes(data);
      })
      .catch(() => setScenes([]));
  }, [filter, sort])

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = {
      filter,
      sort,
      page,
    };

    ApiService.getScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setPage((before) => {
          return before + 1;
        });
        setScenes([...scenes, ...data]);
        setLoadingMore(false);
      });
  }, [filter, sort, scenes, page]);

  const init = useCallback(() => {
    setPage(1);
    setIsLastScene(false);
    fetchScenes();
  }, [setPage, setIsLastScene, fetchScenes]);

  useEffect(() => {
    init();
  }, [sort, filter, init]);


  return <AppBar title={<FormattedMessage id="home.title" />} showSignUpButton isPrimary>
    <Typography variant="subtitle1" py={2.5} px={2}>
      <FormattedMessage id="guest.scene.subtitle" />
    </Typography>
    <GenreSearch />
    <Stack direction="column" spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3}>
      {scenes.map((scene, index) => {
        return <SceneDummyComp
          scene={scene}
          showDivider={index < scenes.length - 1}
          onClick={() => setIsOpen(true)}
        />
      })}
    </Stack>
    {scenes.length > 0 && !isLastScene && (
      <Stack
        marginTop={2}
        width="100%"
        px={2}
      >
        <Button
          onClick={fetchNext}
          variant="outlined"
          fullWidth
          disabled={loadingMore}
          sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
        >
          <FormattedMessage id="home.load_more" />
        </Button>
      </Stack>
    )}
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>
        <Typography variant="body1">
          <FormattedMessage id="guest.scene.dialog.description" />
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 0 }} >
        <Button
          variant='contained'
          size="large"
          onClick={() => {
            navigateTo('/users/signup')
          }}
        >
          <FormattedMessage id="guest.scene.dialog.signup" />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
    <AgeConfirm backgroundType='white' full={false} />
  </AppBar>
};

export default GuestScene;
