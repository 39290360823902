import { Plan } from "@/services/models";
import { List, Table, TableHead, TableRow, TableCell, Typography, TableBody, Stack } from "@mui/material"
import _ from "lodash"
import { FormattedMessage } from "react-intl"

const FREE_PLAN_ID = 1;

type Props = {
  plans: Plan[]
};
const PlanFeatureDescription = (props: Props) => {
  const { plans } = props;
  return (
    <Stack
      spacing={1}
      padding="8px 0"
      width="100%"
    >
      <Typography variant="subtitle1">
        <FormattedMessage id="user.plan.comparison" />
      </Typography>
      <List
        sx={{
          width: '100%',
          marginTop: 0,
          borderRadius: '4px',
          padding: 0,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          background: 'linear-gradient(180deg, rgba(255, 64, 148, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1"><FormattedMessage id="user.plan.feature" /></Typography></TableCell>
              {plans.map((plan) => (
                <TableCell align="center"><Typography sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontFamily: 'Roboto',
                  background: plan.id === FREE_PLAN_ID ? 'transparent' : '#D8B89A',
                  padding: '6px 12px',
                  borderRadius: '50px',
                }}>{plan.name}</Typography></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              [{
                label: <FormattedMessage id="user.plan.character.label" />,
                id: 'max_characters',
                unit: <FormattedMessage id="user.plan.character.unit" />,
              }, {
                label: <FormattedMessage id="user.plan.swap.label" />,
                id: 'max_videos_per_month',
                unit: <FormattedMessage id="user.plan.swap.unit" />,
                unit_after: <FormattedMessage id="user.plan.swap.unit_after" />,
              }, {
                label: <FormattedMessage id="user.plan.favorite.label" />,
                id: 'max_favorite_switch',
                unit: <FormattedMessage id="user.plan.favorite.unit" />,
              }, {
                label: <FormattedMessage id="user.plan.parallel_switch.label" />,
                id: 'max_parallel_switch',
                unit: <FormattedMessage id="user.plan.parallel_switch.unit" />,
              }, {
                label: <FormattedMessage id="user.plan.av.label" />,
                is_av: true,
              }].map(item => (
                <TableRow>
                  <TableCell sx={{ paddingX: '12px' }}><Typography variant="body2">{item.label}</Typography></TableCell>
                  {plans.map((plan) => (
                    <TableCell align="center" sx={{ paddingX: '12px' }}>
                      {
                        item.id ? (
                          <Typography variant="body2">
                            {_.get(plan, item.id)}{item.unit}{_.get(plan, item.id) && item.unit_after ? <><br />{item.unit_after}</> : null}</Typography>
                        ) : (
                          <Typography variant="body2">
                            <FormattedMessage id={plan.id === FREE_PLAN_ID ? "user.plan.av.free" : "user.plan.av.paid"} />
                          </Typography>
                        )
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </List>
    </Stack>
  )
}

export default PlanFeatureDescription;