import SceneSwitchedComp from "@/components/Scene/switched";
import { setTutorialStep } from "@/services/actions/commonActions";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector } from "@/services/hooks";
import { SwitchedScene } from "@/services/models";
import { TUTORIAL_STEP, getTutorialSelect } from "@/services/tutorial";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import SwitchedImage from "@/pages/Home/SwitchedImage";
import RevivalPaymentBanner from "@/components/Banner/RevivalPaymentBanner";

const LIMIT = 10;
type PropTypes = {
  tabType: 'scene' | 'image';
}

export default function Switched(props: PropTypes) {
  const { tabType } = props;
  const dispatch = useDispatch();
  const completeToggle = useSelector((state) => state.common.progress.complete_toggle);
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isSwitchTutorial = tutorialStep === TUTORIAL_STEP.switch;
  const isViewTutorial = tutorialStep === TUTORIAL_STEP.view;
  const isNotTutorial = !tutorialStep || tutorialStep === TUTORIAL_STEP.done;
  const { user } = useSelector((state) => state.user);


  const [page, setPage] = useState(1);
  const [scenes, setScenes] = useState<SwitchedScene[]>([]);
  const [tutorialScene, setTutorialScene] = useState<SwitchedScene | null>(null);
  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const init = () => {
    setPage(1);
    setIsLastScene(false);
    setScenes([]);
    fetchScenes();
  }

  const fetchScenes = () => {
    // myscene で tutorialか無料ユーザー
    if (isViewTutorial || user?.is_free_plan) {
      const selected = getTutorialSelect();
      setScenes([]);
      ApiService.getTutorialSwitchedScene(selected.characterId, selected.sceneId).then((data) => {
        if (!isNotTutorial) {
          dispatch(setTutorialStep(TUTORIAL_STEP.character));
        }
        setTutorialScene(data);
      }).catch(() => { });
      if (!isNotTutorial) {
        return
      }
    }
    if (isSwitchTutorial) {
      setScenes([]);
      return;
    }

    const option = { page: 0 };

    ApiService.getSwitchedScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setScenes(data);
      })
      .catch(() => setScenes([]));
  }

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = { page };

    ApiService.getSwitchedScenes(option)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "click_load_more",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setPage((before) => {
          return before + 1;
        });
        setScenes([...scenes, ...data]);
        setLoadingMore(false);
      });
  }, [scenes, page]);

  const onFavoriteChanged = (sceneId: number) => {
    setScenes(scenes.map(scene => {
      if (scene.id === sceneId) scene.is_favorited = !scene.is_favorited;
      return scene;
    }));
  }

  useEffect(() => {
    init()
  }, [tutorialStep, user, completeToggle])

  // TODO スイッチ完了時にリロード

  return (
    <>
      <Stack direction="column" spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3}>
        {tabType === 'scene' && (
          <>
            <Stack direction="column" spacing={0} marginTop={0} gap={3}>
              <RevivalPaymentBanner showText />
              {scenes.map((scene, index) => (
                <Box key={`${scene.id}_${index}`}>
                  <SceneSwitchedComp
                    isTutorial={isSwitchTutorial}
                    index={index}
                    scene={scene}
                    showDivider={index < scenes.length - 1}
                    onFavoriteChanged={(id) => onFavoriteChanged(id)}
                  />
                </Box>
              ))}

              {scenes.length > 0 && !isLastScene && (
                <Stack
                  marginTop={2}
                  width="100%"
                >
                  <Button
                    onClick={fetchNext}
                    variant="outlined"
                    fullWidth
                    disabled={loadingMore}
                    sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
                  >
                    <FormattedMessage id="home.load_more" />
                  </Button>
                </Stack>
              )}

              {tutorialScene && (
                <Box>
                  <SceneSwitchedComp
                    isTutorial={isSwitchTutorial}
                    index={0}
                    scene={tutorialScene}
                    showDivider={false}
                    onFavoriteChanged={(id) => onFavoriteChanged(id)}
                  />
                </Box>
              )}
            </Stack>
            {
              (!scenes.length && !tutorialScene) && (
                <Box sx={{ width: '100%', textAlign: 'center' }} color="text.secondary">
                  <FormattedMessage id="home.empty_switched" />
                </Box>
              )
            }
          </>
        )}
      </Stack>
      {!user?.is_free_plan && tabType === 'image' && (
        <SwitchedImage />
      )}
    </>
  );
}