import _ from 'lodash';
import { useSelector } from "@/services/hooks";
import { mainTheme } from "@/services/themes"
import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { useMemo } from 'react';
import { Notice } from '@/services/models';

export const ImportantNotice = () => {
  const { notices } = useSelector((state) => state.common);

  const notice = useMemo(() => {
    return _.find(notices, (item: Notice) => {
      return item?.is_important && !item?.is_read;
    }) || null;
  }, [notices]);

  if (!notice) {
    return null;
  }

  return (
    <Box padding={2} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: '10px' }}>
      <Typography variant="subtitle2">{notice.title}</Typography>
      <Typography variant="subtitle2" textAlign="right" style={{ marginTop: '10px' }}>
        <Link to="/notice" style={{ textDecoration: 'none', color: mainTheme.palette.primary.main }}>詳細</Link>
      </Typography>
    </Box>
  )
}