import { v4 as uuidv4 } from "uuid";
import { getSession, setSession } from "@/utils"

const TEMP_USER_KEY = 'tmp_user_id';

export const getTempUserId = () => {
  return getSession(TEMP_USER_KEY);
}

export const setTempUserId = (val: string) => {
  setSession(TEMP_USER_KEY, val);
}

export const initTempUserId = () => {
  const id = getTempUserId();
  if (!id) {
    setTempUserId(uuidv4())
  }
}