import { color } from '@/services/themes';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type PropTypes = {
  children: string | React.ReactElement;
}

// const color1 = color.main;

const color1 = color.main;
const color2 = color.dark;

const Ribbon = (props: PropTypes) => {
  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'inline-block',
        position: 'relative',
        height: 45,
        textAlign: 'center',
        boxSizing: 'border-box',
        zIndex: 1,
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '10px',
          bottom: '-10px',
          left: '-25px',
          zIndex: -2,
          border: `20px solid ${color1}`,
          borderLeftColor: 'transparent',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '10px',
          bottom: '-10px',
          right: '-25px',
          zIndex: -2,
          border: `20px solid ${color1}`,
          borderRightColor: 'transparent',
        }
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          display: 'inline-block',
          position: 'relative',
          margin: 0,
          padding: '0 20px',
          lineHeight: '45px',
          fontSize: '18px',
          color: '#FFF',
          backgroundColor: color1,
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: 0,
            border: 'none',
            borderBottom: '10px solid transparent',
            borderRight: `15px solid ${color2}`,
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            top: '100%',
            right: 0,
            border: 'none',
            borderBottom: '10px solid transparent',
            borderLeft: `15px solid ${color2}`,
          },
        }}
      >
        {props.children}
      </Typography>
    </Box>
  );
};

export default Ribbon;