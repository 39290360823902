import { Button, Modal, Stack } from "@mui/material";
import ReactCrop, { Crop } from 'react-image-crop';
import { useEffect, useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css'

type propType = {
  image?: string;
  isImageModalOpen: boolean;
  handleClose: () => void;
  setPreviewUrl: Function;
}

export const ImageUploadModal = (props: propType) => {
  const { image, isImageModalOpen, handleClose, setPreviewUrl } = props;
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0, width: 100, height: 100, unit: 'px' });
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current) {
      const w = imageRef.current.width;
      const h = imageRef.current.height;
      const _crop: Crop = w > h ? {
        x: (w - h) / 2,
        y: 0,
        width: h,
        height: h,
        unit: 'px',
      } : {
        x: 0,
        y: (h - w) / 2,
        width: w,
        height: w,
        unit: 'px',
      }
      setCrop(_crop);
      makeClientCrop(_crop);
    }
  }, [imageRef.current])

  const getCroppedImg = (image: HTMLImageElement, completedCrop: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const width = completedCrop.width;
    const height = completedCrop.height;

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx?.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      width,
      height,
    );

    return new Promise<string>((resolve) => {
      const base64 = canvas.toDataURL('image/png');
      resolve(base64);
    });
  };

  const makeClientCrop = async (_crop: Crop) => {
    if (imageRef && imageRef.current && _crop.width && _crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        _crop,
      );
      setCrop(prev => ({ ...prev, ..._crop }));
      setCroppedImage(croppedImageUrl);
    }
  };

  return (
    <Modal
      open={isImageModalOpen}
      onClose={handleClose}
    >
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="scroll"
        sx={{ height: '100%' }}
      >
        <Stack
          padding={4}
          spacing={2}
          sx={{ background: 'white' }}
        >
          <ReactCrop
            className="react-crop"
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={1}
            onComplete={makeClientCrop}
          >
            <img
              ref={imageRef}
              src={image}
              style={{ maxWidth: '800px', width: '100%' }}
            />
          </ReactCrop>

          <Button
            variant="contained"
            onClick={() => {
              setPreviewUrl(croppedImage);
              handleClose();
            }}
          >
            OK
          </Button>
        </Stack>
      </Stack>
    </Modal>

  )
}