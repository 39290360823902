import { User } from "@/services/models"
import { getMonetrackCode, isShowTags } from "@/components/AdTag/functions"

type MonetrackLeadThanksProps = {
  user: User
}

export const MonetrackAllPage = () => {
  if (!isShowTags()) {
    return ''
  }
  return (
    <script type='text/javascript'>
      {`(function(d) {
  var scriptTrack = document.createElement('script');
  scriptTrack.src = 'https://assets.monetrack.com/scripts/track.js';
  scriptTrack.async = true;
  document.getElementsByTagName('head')[0].appendChild(scriptTrack);
})(document);`}
    </script>
  )
}

// 会員登録完了ページの body に貼り付けるタグ
export const MonetrackLeadThanks = ({ user }: MonetrackLeadThanksProps) => {
  if (!isShowTags()) {
    return ''
  }
  const code = getMonetrackCode()
  const campaign_id = "FS001";
  return (
    <img
      style={{ display: "none" }}
      src={`https://app.monetrack.com/sys/lead.js?visitor_id=${code}&order_id=${user.key}&campaign_id=${campaign_id}&action_email=${user.email}`}
      width="1"
      height="1"
    />
  )
}
