import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Box, Modal, Snackbar, Typography } from "@mui/material";
import jaMessages from '@/languageProvider/locales/ja_JPN';

import { LoginLayout } from "@/layouts/LoginLayout";
import { routes } from "@/routes";
import { UserContextProvider } from "@/contexts/user";
import { closeAlertModal, closeSnackbar, fetchNotices, setGentamaCode, setMonetrackCode, setReferralCode, setReferrer } from "@/services/actions/commonActions";
import RegistrationDialog from "@/components/Dialog/registration";
import PlanDialog from "@/components/Dialog/plan";
import { useDispatch, useSelector } from '@/services/hooks';
import CharacterSwapDialog from "@/components/Dialog/character_swap";
import { trackingUrlParam, getSession, isCrawler } from "@/utils";
import { isAdult } from "@/constants";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
import { GentamaBeforeThanks } from "@/components/AdTag/gentama";
import { MonetrackAllPage } from "@/components/AdTag/monetrack";
import { getGentamaCode, getMonetrackCode } from "@/components/AdTag/functions";
import MaintenanceDialog from "@/components/Dialog/maintenance";
import SwitchDialog from "@/components/Dialog/switch";
import LoginBonusDialog from "@/components/Dialog/login_bonus";
import SwitchImageDialog from "@/components/Dialog/switch_image";
import CharacterNewDialog from "@/components/Dialog/character_new";
import SwitchedFeedbackDialog from "@/components/Dialog/switched_feedback";
import LimitedTimeCampaignDialog from "@/components/Dialog/limited_campaign";

export const BaseLayout: React.FC = () => {
  const { snackbar } = useSelector((state) => state.common);
  const { alert_modal } = useSelector((state) => state.common);
  const { user, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateTo = useNavigate();

  useEffect(() => {
    dispatch(setReferrer(document.referrer));
    // 管理画面からは#で渡されるけど特定のアフィ業者用の裏メニュー
    // クエリパラメータにreferral_codeがある場合はそれを優先する
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get("referral_code") || window.location.hash.replace('#', '');
    if (referralCode) {
      dispatch(setReferralCode(referralCode));
    }
    const gentamaCode = getGentamaCode();
    if (gentamaCode) {
      dispatch(setGentamaCode(gentamaCode));
    }
    const monetrackCode = getMonetrackCode();
    if (monetrackCode) {
      dispatch(setMonetrackCode(monetrackCode));
    }
  }, [])

  useEffect(() => {
    const ignorePathNames = ['/demo', '/verify', '/guest/scenes', '/terms'];
    if (isCrawler() || ignorePathNames.includes(location.pathname)) {
      return;
    }
    const isSeen = !!getSession('age_confirm');
    if (!isSeen && isAdult) {
      navigateTo(`/demo${trackingUrlParam()}`, {
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchNotices()).catch(() => {
        // notice error
      });
    }
  }, [user])


  const onCloseSnackbar = () => {
    dispatch(closeSnackbar());
  };

  return (
    <UserContextProvider
      value={{
        user: user,
        loading: loading,
      }}
    >
      <Routes>
        {routes.map((route, idx) => {
          const siteName = 'FACESWITCH';
          // 検索エンジンクローラーがトップにアクセスした際、Loginページにリダイレクトされる
          // Loginのタイトルがトップページのタイトルとして扱われてしまう可能性があるため、デモページの場合はタイトルにLoginをつけない
          const title = (route.title === 'Login' && isCrawler()) || !route.title ? siteName : `${route.title} | ${siteName}`
          const description = jaMessages['demo.modal_description']
          const ldJson = {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": siteName,
            "description": description,
            "url": "https://www.face-switch-ai.com/",
          }
          const head = <>
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* 固有の情報を載せるほどのページは今のところないのでOGPの情報も固定 */}
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="./faceswitch_logo_pink@3x.png" />
            <meta property="og:url" content={window.location.href} />
            <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
            {route.path === '/users/plan/complete' || GentamaBeforeThanks()}
          </>
          return (
            <Route
              key={idx}
              path={route.path}
              element={
                route.login_required ? (
                  <LoginLayout>
                    <Helmet>
                      {head}
                    </Helmet>
                    <route.component />
                  </LoginLayout>
                ) :
                  (<>
                    <Helmet>
                      {head}
                    </Helmet>
                    <route.component />
                  </>
                  )
              }
            />
          );
        })}
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          position: 'fixed',
          top: snackbar.position === 'top' ? (theme) => theme.spacing(8) : 'auto !important',
          bottom: snackbar.position === 'bottom' ? (theme) => theme.spacing(8) : 'auto',
          left: {
            xs: '8px',
            sm: 'calc(50% + 120px)',
          },
        }}
      />
      <Modal
        open={alert_modal.open}
        onClose={() => dispatch(closeAlertModal())}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap={2}
          position="absolute"
          top="240px"
          width="calc(100vw - 20px)"
          minHeight="107px"
          maxWidth="400px"
          left="50%"
          sx={{
            background: 'white',
            borderRadius: 1,
            padding: 2,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography variant="body1" fontWeight="bold" fontSize={18}>
            {alert_modal.message}
          </Typography>
          <Typography
            color="primary"
            variant="body1"
            display="flex"
            justifyContent="flex-end"
          >
            <Box onClick={() => dispatch(closeAlertModal())} sx={{ cursor: 'pointer' }}>
              <FormattedMessage id="alert_modal.close" />
            </Box>
          </Typography>
        </Box>
      </Modal>
      <RegistrationDialog />
      <PlanDialog />
      {/* 廃止予定 */}
      <CharacterSwapDialog />
      <SwitchDialog />
      <SwitchImageDialog />
      <MonetrackAllPage />
      <MaintenanceDialog />
      <LoginBonusDialog />
      <CharacterNewDialog />
      <SwitchedFeedbackDialog />
      <LimitedTimeCampaignDialog /> {/* TODO: 決済復活キャンペーン終了したら消す */}
    </UserContextProvider>
  );
};
