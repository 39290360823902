import { getSession, setSession } from "@/utils"

const PROGRESS_KEY = 'progress';


// 閉じた時に開始していたかどうか
export const isProgressStart = () => {
  return getSession(PROGRESS_KEY) === 'start';
}

export const startProgressSession = () => {
  setSession(PROGRESS_KEY, 'start');
}

export const endProgressSession = () => {
  setSession(PROGRESS_KEY, 'end');
}
