import { ReactElement } from "react";
import _ from "lodash";
import { Notice, Scene, SwappedImageHistory, CheckReuploadingStatusResponse } from "@/services/models";
import { getTutorialStepSession, TUTORIAL_STEP } from "@/services/tutorial";

interface CommonState {
  snackbar: { open: boolean, message: string | ReactElement | null, position: 'top' | 'bottom' };
  alert_modal: { open: boolean, message: string | ReactElement | null };
  registration_popup: boolean,
  plan_dialog: { open: boolean, use_type: string | null };
  // 廃止予定
  character_swap_dialog: { open: boolean, video_uid: string | null, scene_id: number | null, point: number, is_tutorial: boolean, };
  switch_dialog: { open: boolean, scene: Scene | null, character_id: number | null, onClose: () => void | null | undefined };
  switch_image_dialog: { open: boolean, swapped_image_history: SwappedImageHistory | null };
  character_new_dialog: { open: boolean };
  switched_feedback_dialog: { open: boolean, scene_id: number | null };
  revival_payment_campaign_dialog: { open: boolean }; // TODO: 決済復活キャンペーン終了したら消す
  referral_code: string;
  gentama_code: string;
  monetrack_code: string;
  referrer: string;
  progress: { polling: boolean, complete_toggle: boolean };
  notices: [] | [Notice];
  tutorial_step?: string | null;
  is_maintenance: boolean;
  login_bonus_dialog: { open: boolean };
  reuploading: { polling: boolean, statuses: CheckReuploadingStatusResponse['swaps'] };
}

const initialState: CommonState = {
  snackbar: { open: false, message: null, position: 'top' },
  alert_modal: { open: false, message: null },
  registration_popup: false,
  plan_dialog: { open: false, use_type: 'view' },
  character_swap_dialog: { open: false, video_uid: null, scene_id: null, point: 0, is_tutorial: false, },
  switch_dialog: { open: false, scene: null, character_id: null, onClose: () => {} },
  switch_image_dialog: { open: false, swapped_image_history: null },
  character_new_dialog: { open: false },
  switched_feedback_dialog: { open: false, scene_id: null },
  revival_payment_campaign_dialog: { open: false }, // TODO: 決済復活キャンペーン終了したら消す
  referral_code: '',
  gentama_code: '',
  monetrack_code: '',
  referrer: '',
  progress: { polling: false, complete_toggle: false },
  notices: [],
  tutorial_step: getTutorialStepSession(),
  is_maintenance: false,
  login_bonus_dialog: { open: false },
  reuploading: { polling: false, statuses: [] },
};

export const commonReducer = (state = initialState, action: any): CommonState => {
  switch (action.type) {
    case "OPEN_SNACKBAR":
      return { ...state, snackbar: { open: true, message: action.message, position: action.position } };
    case "CLOSE_SNACKBAR":
      return { ...state, snackbar: { open: false, message: null, position: state.snackbar.position } };
    case "OPEN_ALERT_MODAL":
      return { ...state, alert_modal: { open: true, message: action.message } };
    case "CLOSE_ALERT_MODAL":
      return { ...state, alert_modal: { open: false, message: null } };
    case "OPEN_REGISTRATION_POPUP":
      return { ...state, registration_popup: true };
    case "CLOSE_REGISTRATION_POPUP":
      return { ...state, registration_popup: false };
    case "OPEN_PLAN_DIALOG":
      return { ...state, plan_dialog: { open: true, use_type: action.use_type } };
    case "CLOSE_PLAN_DIALOG":
      return { ...state, plan_dialog: { ...state.plan_dialog, open: false } };
    case "OPEN_CHARACTER_SWAP_DIALOG":
      return { ...state, character_swap_dialog: { open: true, video_uid: null, scene_id: action.scene_id, point: action.point, is_tutorial: action.is_tutorial, } };
    case "OPEN_CHARACTER_SWAP_DIALOG_WITH_SAMPLE":
      return { ...state, character_swap_dialog: { open: true, video_uid: action.video_uid, scene_id: null, point: 0, is_tutorial: false, } };
    case "CLOSE_CHARACTER_SWAP_DIALOG":
      return { ...state, character_swap_dialog: { open: false, video_uid: null, scene_id: null, point: 0, is_tutorial: false, } };
    case "OPEN_SWITCH_DIALOG":
      return { ...state, switch_dialog: { open: true, scene: action.scene, character_id: action.character_id, onClose: action.onClose } };
    case "CLOSE_SWITCH_DIALOG":
      return { ...state, switch_dialog: { open: false, scene: null, character_id: null, onClose: () => {} } };
    case "OPEN_SWITCH_IMAGE_DIALOG":
      return { ...state, switch_image_dialog: { open: true, swapped_image_history: action.swapped_image_history } };
    case "CLOSE_SWITCH_IMAGE_DIALOG":
      return { ...state, switch_image_dialog: { open: false, swapped_image_history: null } };
    case "OPEN_SWITCHED_FEEDBACK_DIALOG":
      return { ...state, switched_feedback_dialog: { open: true, scene_id: action.scene_id } };
    case "CLOSE_SWITCHED_FEEDBACK_DIALOG":
      return { ...state, switched_feedback_dialog: { open: false, scene_id: null } };
    case "SET_REFERRAL_CODE":
      return { ...state, referral_code: action.referral_code };
    case "SET_GENTAMA_CODE":
      return { ...state, gentama_code: action.gentama_code };
    case "SET_MONETRACK_CODE":
      return { ...state, monetrack_code: action.monetrack_code };
    case "SET_REFERRER":
      return { ...state, referrer: action.referrer };
    case "SET_PROGRESS_POLLING":
        return { ...state, progress: { ...state.progress, polling: action.value } };
    case "TOGGLE_PROGRESS_COMPLETE":
        return { ...state, progress: { ...state.progress, complete_toggle: !state.progress.complete_toggle  } };
    case "SET_REUPLOADING_POLLING":
      return { ...state, reuploading: { ...state.reuploading, polling: action.polling } };
    case "SET_REUPLOADING_STATUS":
        return { ...state, reuploading: { ...state.reuploading, statuses: action.statuses } };
    case "FETCH_NOTICE_SUCCESS":
      return  { ...state, notices: action.payload };
    case "READ_NOTICE_SUCCESS": {
      const d = _.find(state.notices, { id: Number(action.noticeId)});
      if (d) {
        d.is_read = true;
      }
      return  { ...state, notices: [...state.notices] };
    }
    case "USER_INFO_FAIL":
    case "USER_LOGOUT":
    case "USER_LOGIN_FAIL":
      return { ...state, notices: [] };
    case "SET_TUTORIAL_STEP":
      return { ...state, tutorial_step: action.step };
    case "UPDATE_MAINTENANCE_SUCCESS":
      return { ...state, is_maintenance: action.value };
    case "OPEN_LOGIN_BONUS_DIALOG":
      if (state.tutorial_step && state.tutorial_step !== TUTORIAL_STEP.done) {
        return state;
      }
      return { ...state, login_bonus_dialog: { open: true }};
    case "CLOSE_LOGIN_BONUS_DIALOG":
      return { ...state, login_bonus_dialog: { open: false }};
    // TODO: 決済復活キャンペーン終了したら消す
    case "OPEN_REVIVAL_PAYMENT_CAMPAIGN_DIALOG":
      return { ...state, revival_payment_campaign_dialog: { open: true }};
    case "CLOSE_REVIVAL_PAYMENT_CAMPAIGN_DIALOG":
      return { ...state, revival_payment_campaign_dialog: { open: false }};
    // ここまで
    case "OPEN_CHARACTER_NEW_DIALOG":
      return { ...state, character_new_dialog: { open: true }};
    case "CLOSE_CHARACTER_NEW_DIALOG":
      return { ...state, character_new_dialog: { open: false }};
    default:
      return state;
  }
};
