import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";

import { setSession, getSession } from "@/utils";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";

type Props = {
  backgroundType?: 'transparent' | 'white';
  full?: boolean; // true: 画面いっぱいに表示, false: 親要素いっぱいに表示
}

const getColor = (backgroundType: Props['backgroundType']) => {
  switch (backgroundType) {
    case 'white':
      return {
        background: 'rgba(255, 255, 255, 1)',
        image: "/18markGR.png",
        titleColor: 'text.primary',
        descriptionColor: 'text.secondary',
      };
    case 'transparent':
    default:
      return {
        background: 'rgba(0, 0, 0, 0.5)',
        image: "/18markWhite@3x.png",
        titleColor: 'white',
        descriptionColor: 'white',
      };
  }
}

export default function AgeConfirm({
  backgroundType = 'transparent',
  full = true,
}: Props) {
  const [isFirst, setIsFirst] = useState(true);
  const [isSeen, setIsSeen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsSeen(!!getSession('age_confirm'));
    setIsFirst(false);
  }, []);

  useEffect(() => {
    const preventScroll = (e: TouchEvent) => {
      e.preventDefault();
    };
    if (!isSeen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'fixed'; // スクロールを完全に止めるためにposition: fixedを使用
      document.body.style.width = '100%'; // レイアウト崩れを防ぐために幅を固定
      window.scrollTo(0, 0); // スクロール位置をリセット

      // タッチスクロールの防止（特にiOSで有効）
      document.addEventListener('touchmove', preventScroll, { passive: false });

      const scrollResetTimeout = setTimeout(() => {
        window.scrollTo(0, 0); // 確実にスクロールをリセット
      }, 1000);

      return () => {
        // クリーンアップ：タイムアウトとイベントリスナーの解除
        clearTimeout(scrollResetTimeout);
        document.removeEventListener('touchmove', preventScroll);
      };
    } else {
      // スクロールを再度有効にする
      document.body.style.overflowY = 'auto';
      document.body.style.position = 'relative'; // fixedから戻す
      document.body.style.width = 'auto'; // 幅を元に戻す
    }
  }, [isSeen])

  if (isSeen || isFirst) {
    return null;
  }

  const {
    background,
    image,
    titleColor,
    descriptionColor,
  } = getColor(backgroundType);

  return (
    <Stack
      position={full ? "fixed" : "absolute"}
      top={0}
      left={0}
      width="100%"
      height="100vh"
      sx={{ zIndex: 100 }}
    >
      <Stack
        direction="column"
        spacing={2}
        margin={0}
        paddingBottom={2}
        paddingTop={windowHeight >= 665 ? '260px' : `${Math.max(260 - 665 + windowHeight, 0)}px`}
        sx={{
          background: background,
          minHeight: 'calc(100vh - 64px)',
          height: '100%',
        }}
      >
        <Stack direction="column" spacing={2} margin={0} paddingY={3} paddingX={2} gap={2} >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              display="flex"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="h5"
                color={titleColor}
              >
                <FormattedMessage id="age_confirm.title" />
              </Typography>
              <img
                src={image}
                alt="18mark"
                style={{
                  width: '48px',
                  height: '48px',
                  display: 'block',
                  margin: '8px auto',
                }}
              />
            </Box>
            <Typography
              variant="h6"
              color={titleColor}
            >
              <FormattedMessage id="age_confirm.age_confirm" />
            </Typography>
          </Box >
          <Typography
            variant="body1"
            color={descriptionColor}
            margin={0}
            textAlign={'center'}
            fontSize="12px"
          >
            <FormattedMessage id="age_confirm.message1" />
            <br />
            <FormattedMessage id="age_confirm.message2" />
          </Typography>
        </Stack >
        <Box
          display="flex"
          gap={2}
          margin={0}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "click_age_confirm_no",
                }
              });
              window.location.href = 'https://www.google.com/';
            }}
            sx={{
              width: '100%',
              maxWidth: '164px',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
              }
            }}
          >
            <FormattedMessage id="age_confirm.no" />
          </Button>

          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {
              setSession('age_confirm', 'true');
              TagManager.dataLayer({
                dataLayer: {
                  event: "click_age_confirm_yes",
                }
              });
              setIsSeen(true);
            }}
            sx={{
              width: '100%',
              maxWidth: '164px'
            }}
          >
            <FormattedMessage id="age_confirm.yes" />
          </Button>

        </Box>
      </Stack >
    </Stack >
  );
}
