import { backToPlanPagePath } from "@/constants";
import { ApiService } from "@/services/api";
import { useSelector } from "@/services/hooks";
import { color } from "@/services/themes";
import { Stack, Button, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const BottomUpgradeBanner = () => {
  const navigateTo = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [count, setCount] = useState(0);
  const bannerId = 'BottomUpGradeBan01';

  useEffect(() => {
    ApiService.getSceneCount().then((res) => {
      if (res < 10) {
        setCount(res);
      } else {
        setCount(res - res % 10);
      }
    }).catch(() => {
      setCount(360); // エラーの時とりあえず
    })
  }, []);

  return (
    <Stack mt={2} paddingX={2} gap={2}>
      <Stack>
        <img style={{ width: '100%' }} src={`/home/${bannerId}@2x.png`} srcSet={`/home/${bannerId}@3x.png 2x,/home/${bannerId}@3x.png 3x`} alt="banner" />
      </Stack>

      <Box
        width="100%"
        display="flex"
        gap={1}
        justifyContent="start"
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '16px',
            textAlign: 'center',
            color: color.main,
            backgroundColor: '#F2F2F2',
            lineHeight: 1,
            padding: '8px 16px',
          }}
        >
          <FormattedMessage
            id="home.bottom_upgrade_banner.text_01"
            values={{
              num: count,
            }}
          />
        </Typography>

        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '16px',
            textAlign: 'center',
            color: color.main,
            backgroundColor: '#F2F2F2',
            lineHeight: 1,
            padding: '8px 16px',
          }}
        >
          <FormattedMessage id="home.bottom_upgrade_banner.text_02" />
        </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{
          textAlign: 'left',
        }}
      >
        <FormattedMessage id="home.bottom_upgrade_banner.description" />
      </Typography>

      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          navigateTo(backToPlanPagePath)
          TagManager.dataLayer({
            dataLayer: {
              event: "move_plan_from_upgrade_banner",
              banner_id: bannerId,
              user_id: user?.id,
            }
          });
        }}
      >
        <FormattedMessage id="home.bottom_upgrade_banner.button" />
      </Button>
    </Stack>
  )
}
export default BottomUpgradeBanner;