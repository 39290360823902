import React, { ReactElement, useState } from "react";

import {
  TextField,
  Button,
  Stack,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
import { ApiService } from "@/services/api";
import { useDispatch } from '@/services/hooks';
import { openSnackbar } from "@/services/actions/commonActions";

const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const Contact: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSended, setIsSended] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [errors, setErrors] = useState<{ email: string | ReactElement; name: string | ReactElement; content: string | ReactElement }>({
    email: "",
    name: "",
    content: "",
  });
  const disabled = !(name.length > 0 && content.length > 0 && emailRegex.test(email)) || isLoading || isSended;

  const onClick = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    ApiService.sendContact({
      email, name, content
    }).then((res) => {
      setIsLoading(false);
      if (res.success) {
        setIsSended(true)
        setName('')
        setEmail('')
        setContent('')
        dispatch(openSnackbar(<FormattedMessage id="contact.send_success" />))
      } else {
        dispatch(openSnackbar(<FormattedMessage id="contact.send_failed" />))
      }
    }).catch((e) => {
      setIsLoading(false);
      console.log(e);
      dispatch(openSnackbar(<FormattedMessage id="contact.send_failed" />))
    })
  };

  return (
    <AppBar title={<FormattedMessage id="contact.title" />}>
      <Stack padding={2}>
        {/* <Typography variant="h4" gutterBottom>
          お問い合わせ
        </Typography> */}
        <TextField
          fullWidth
          margin="normal"
          label={<FormattedMessage id="contact.name" />}
          variant="outlined"
          required={true}
          value={name}
          onChange={(e) => {
            const value = e.target.value;
            setName(value);
            const error = value.length > 0 ? "" : <FormattedMessage id="common.name.required" />;
            setErrors((before) => {
              return {
                ...before,
                name: error,
              }
            })
          }}
          helperText={errors.name}
        />
        <TextField
          fullWidth
          margin="normal"
          label={<FormattedMessage id="contact.mail" />}
          variant="outlined"
          required={true}
          value={email}
          onChange={(e) => {
            const value = e.target.value;
            setEmail(value);
            const error = emailRegex.test(value) ? "" : <FormattedMessage id="common.invalid.email_format" />;
            setErrors((before) => {
              return {
                ...before,
                email: error,
              }
            })
          }}
          helperText={errors.email}
        />
        <TextField
          fullWidth
          margin="normal"
          label={<FormattedMessage id="contact.content" />}
          variant="outlined"
          multiline
          rows={5}
          required={true}
          value={content}
          onChange={(e) => {
            const value = e.target.value;
            setContent(value);
            const error = value.length > 0 ? "" : <FormattedMessage id="common.content.required" />;
            setErrors((before) => {
              return {
                ...before,
                content: error,
              }
            })
          }}
          helperText={errors.content}
        />
        <Button
          variant="contained"
          color="primary"
          type="button"
          style={{ marginTop: "20px" }}
          disabled={disabled}
          onClick={onClick}
        >
          <FormattedMessage id="contact.submit" />
        </Button>
      </Stack>
    </AppBar>
  );
};

export default Contact;
