import { Add } from "@mui/icons-material";
import { mainTheme } from "@/services/themes";
import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { openPlanDialog, openRegistrationPopup, setTutorialStep } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { useNavigate } from "react-router-dom";
import { useUserContext } from "@/contexts/user";
import { useRef, useState } from "react";
import { TUTORIAL_STEP } from "@/services/tutorial";
import CharacterHint from "@/components/common/Hint/CharacterHint";
import TagManager from "react-gtm-module";

interface Props {
  isSwitchTutorial?: boolean,
  isLogin: boolean,
  characterId?: number | null,
  createOnClick: (characterId: number) => () => void,
  closeDialog: () => void,
}

export default function CharacterList(props: Props) {
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isCharacterTutorial = tutorialStep === TUTORIAL_STEP.character;
  const { characters } = useSelector((state) => state.character);
  const { isSwitchTutorial, isLogin, characterId, createOnClick, closeDialog } = props;
  const { user } = useUserContext();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.user);
  const scrollStackRef = useRef<HTMLDivElement>(null);
  const [scrollbarHeight, setScrollbarHeight] = useState(0)

  const myCharacters = characters.filter((character) => {
    return character.is_editable;
  })

  const featuredCharacters = characters.filter((character) => {
    return !character.is_editable && myCharacters.length === 0;
  })

  return (
    <Stack gap={0}>
      {featuredCharacters.length > 0 ? (<>
        <Typography variant="body2">< FormattedMessage id="character_list.recommend_character" /></Typography>
        <Stack
          direction="row"
          spacing="8px"
          justifyContent="start-flex"
          sx={{
            overflowX: 'scroll',
            marginBottom: '16px',
            MsOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {featuredCharacters.map((character, index) => {
            return (
              <IconButton
                key={character.id}
                sx={{
                  padding: 0,
                  border: characterId === character.id ? 'solid 2px #F06292' : 'solid 2px transparent',
                }}
                onClick={createOnClick(character.id)}
              >
                <Avatar
                  alt={character.name}
                  src={character.url}
                  key={index}
                  sx={{ width: 60, height: 60 }}
                />
              </IconButton>
            )
          })}
        </Stack></>) : null}
      {isSwitchTutorial ? null : <Typography variant="body2"><FormattedMessage id="character_list.my_character" /></Typography>}
      {isSwitchTutorial ? null :
        myCharacters.length > 0 ? (
          <Stack
            ref={scrollStackRef}
            onMouseOver={() => {
              setScrollbarHeight(scrollStackRef.current
                ? scrollStackRef.current.offsetHeight - scrollStackRef.current.clientHeight
                : 0)
            }}
            direction="row"
            spacing={1}
            justifyContent="start-flex"
            sx={{
              overflowX: 'scroll',
              margin: '4px 0 0',
              ":not(:hover)": {
                MsOverflowStyle: 'none',
                scrollbarWidth: 'none',
              },
              ":hover": {
                marginBottom: `-${scrollbarHeight && scrollbarHeight + 0.5}px`, // なぜか0.5px分ガタつくので、0.5px分マイナス
              }
            }}
          >

            <IconButton
              sx={{
                padding: 0,
                border: 'solid 2px transparent',
              }}
              onClick={() => {
                if (!isLogin) {
                  dispatch(openRegistrationPopup());
                } else if (detail && detail.character.plan_remain < 1) {
                  dispatch(openPlanDialog('character'));
                } else {
                  navigateTo('/characters/new');
                  closeDialog();
                }
              }}
            >
              <Avatar
                sx={{
                  width: 60,
                  height: 60,
                  bgcolor: mainTheme.palette.primary.main,
                }}
              >
                <Add fontSize="large" />
              </Avatar>
            </IconButton>
            {
              myCharacters.map((character, index) => {
                return (
                  <IconButton
                    key={character.id}
                    sx={{
                      padding: 0,
                      border: characterId === character.id ? 'solid 2px #F06292' : 'solid 2px transparent',
                    }}
                    onClick={() => {
                      if (character.is_limited) {
                        dispatch(openPlanDialog('character'));
                        return;
                      }
                      createOnClick(character.id)()
                    }}
                    disabled={user?.is_free_plan && detail?.is_compensation_target ? false : character.is_limited}
                  >
                    <Avatar
                      alt={character.name}
                      src={character.url}
                      key={index}
                      sx={{
                        width: 60,
                        height: 60,
                        filter: character.is_limited ? 'brightness(0.5)' : 'none',
                      }}
                    />
                  </IconButton>
                )
              })
            }


          </Stack>) : (
          <Stack
            position="relative"
            direction="row"
            spacing={2}
            padding="16px 16px 0"
            justifyContent="center"
          >
            <Button
              variant="contained"
              onClick={() => {
                closeDialog();
                if (isCharacterTutorial) {
                  dispatch(setTutorialStep(TUTORIAL_STEP.done));
                }

                if (isLogin && user?.is_free_plan && detail && detail.character.plan_remain <= 0) {
                  dispatch(openPlanDialog('character'));
                } else if (isLogin) {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "move_generate_character",
                      user_id: user?.id,
                    }
                  });
                  navigateTo('/characters/new');
                } else {
                  dispatch(openRegistrationPopup());
                }
              }}
              sx={{ zIndex: isCharacterTutorial ? 101 : 1 }}
            >
              <Add fontSize="medium" /> <FormattedMessage id="character_list.character_create" />
            </Button>
            {isCharacterTutorial && <CharacterHint />}
          </Stack>
        )}

    </Stack >
  )
}
