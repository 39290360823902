import { openSnackbar, setProgressPolling, toggleProgressComplete } from "@/services/actions/commonActions";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector } from "@/services/hooks";
import { CheckGenerateStatusResponse } from "@/services/models";
import { Box, IconButton, Typography } from "@mui/material";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { endProgressSession, isProgressStart } from "@/services/progress";
import ProgressBar from "@/components/Progress/ProgressBar";


export default function Progress() {
  const dispatch = useDispatch();
  const { polling } = useSelector(state => state.common.progress);
  const [swaps, setSwaps] = useState<CheckGenerateStatusResponse['swaps']>([]);
  const intervalId = useRef<number>()
  const [isMinimized, setIsMinimized] = useState(false)

  const checkGenerateStatus = async () => {
    const data = await ApiService.check_generate_status();
    console.log("check_generate_status data: ", data);

    const isEveryCompleted = data.swaps.every((swap) => {
      return swap.status === 'completed'
    })
    if (isEveryCompleted) {
      const hasCompleted = !!data.swaps.filter((swap) => {
        return swap.status === 'completed'
      }).length
      if (hasCompleted) {
        endProgressSession();
        dispatch(openSnackbar(<FormattedMessage id="generate.complete" />));
        dispatch(toggleProgressComplete());
      }

      // 閉じるの間に完了していた場合
      if (data.swaps.length === 0 && isProgressStart()) {
        endProgressSession();
        dispatch(openSnackbar(<FormattedMessage id="generate.complete" />));
        dispatch(toggleProgressComplete());
      }

      dispatch(setProgressPolling(false));
      clearInterval(intervalId.current);
      intervalId.current = undefined
      setSwaps([])
      return;
    }

    const hasInProgress = !!data.swaps.find((swap) => {
      return swap.status !== 'failed' && swap.status !== 'completed';
    })
    if (!polling && hasInProgress) {
      dispatch(setProgressPolling(true));
    }

    const displaySwaps = data.swaps.sort((a, b) => {
      return a.id - b.id
    })
    setSwaps(displaySwaps)
  }

  useEffect(() => {
    checkGenerateStatus();
  }, [])
  useEffect(() => {
    if (polling && !intervalId.current) {
      setIsMinimized(false);
      // 生成中は、3秒ごとに何フィード生成できたかを確認する
      intervalId.current = setInterval(checkGenerateStatus, 3000);
      // 初回のみ即時実行
      checkGenerateStatus();
    }
    return () => clearInterval(intervalId.current);
  }, [polling]);

  if (swaps.length === 0) {
    return null;
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      position="fixed"
      padding={0}
      marginX={1}
      sx={{
        width: isMinimized ? '36px' : {
          xs: 'calc(100vw - 16px)', // 16px: 左右の余白
          sm: 'calc(100vw - 239px - 16px)',  // 239px: サイドメニュー幅, 16px: 左右の余白
        },
        maxHeight: isMinimized ? '36px' : '500px',
        right: 0,
        top: {
          xs: '68px',
          sm: '80px',
        },
        zIndex: 1100,
        transition: 'width .2s, max-height .2s',
      }}
    >
      <Box
        position="relative"
        width="100%"
        sx={{
          boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '3px',
          maxWidth: '600px',
        }}
      >
        <IconButton
          sx={{
            right: isMinimized ? 0 : "10px",
            transition: 'right 0.2s',
            position: 'absolute',
            top: 0,
          }}
          onClick={() => setIsMinimized((v) => !v)}
        >
          {isMinimized ? (
            <UnfoldMore color="primary" fontSize="small" />
          ) : (
            <UnfoldLess color="primary" fontSize="small" />
          )}
        </IconButton>
        <Typography
          variant="caption"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          flexShrink={0}
          paddingX={2}
          overflow='hidden'
          sx={{
            pointerEvents: isMinimized ? 'none' : 'auto',
            opacity: isMinimized ? 0 : 1,
            transition: 'opacity .2s',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingY={1}
            sx={{
              width: '100%',
            }}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
          >
            ※Switchには数分ほど時間を要します
          </Box>
          {swaps.map((swap) => {
            return <ProgressBar
              key={swap.id}
              swap={swap}
              checkGenerateStatus={checkGenerateStatus}
            />
          })}
        </Typography>
      </Box>
    </Box>
  )
}