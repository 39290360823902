import { createTheme } from "@mui/material/styles";
import { isAdult } from "@/constants";

const color = {
  main: isAdult ? "#F06292" : "#42A5F5",
  dark: isAdult ? "#D81B60" : "#388bce",
  light: isAdult ? '#FCE4EC' : "#00d8ff",
  tertiary: '#0288D1',
  secondary: isAdult ? "rgba(255, 64, 148, 0.08)": "#008fff",
  appBarPrimaryBackground: isAdult ? '#F06292': "#FFFFFF",
  appBarBackground: isAdult ? 'rgb(255, 235, 235)': "#E3F2FD",
  sceneEnjoyFill: isAdult ? '#0288D1' : '#616161E5',
  sceneEnjoyButton: isAdult ? "#F06292": "#F06292",
}

const mainTheme = createTheme({
  palette: {
    primary: {
      main:  color.main,
      light: color.light,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: color.secondary,
      contrastText: "#000000",
    },
    tertiary: {
      main: color.tertiary,
      light: '#3AB8FD',
      contrastText: "#FFFFFF",
      dark: "#01456A",
    },
    onetine_item_button: {
      main: '#FFFFFF',
      light: color.tertiary,
      contrastText: "#0288D1",
      dark: "#d1d1d1",
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.60)',
      disabled: 'rgba(0,0,0,0.38)',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Noto Sans JP',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body1: {
      fontFamily: 'Noto Sans JP',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body2: {
      fontFamily: 'Noto Sans JP',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '0.17px',
      color: 'rgba(0, 0, 0, 0.60)',
    },
    subtitle1: {
      fontFamily: 'Noto Sans JP',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Noto Sans JP',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.1px',
    },
    button: {
      textTransform: 'none',
      fontFamily: 'Noto Sans JP',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: '0.46px',
    },
    caption: {
      fontFamily: 'Noto Sans JP',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '0.4px',
    },
    h5: {
      fontFamily: 'Noto Sans JP',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '133.4%',
    },
    h6: {
      fontFamily: 'Noto Sans JP',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.15px',
    }
  }
});

export { mainTheme, color };
