import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  label?: string;
  onClick: () => void;
}

export default function GenreButton(props: Props) {
  return (
    <Box
      width="fit-content"
      minWidth="fit-content"
      sx={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #00000033',
        padding: '6px 10px 6px 4px',
        borderRadius: '18px',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          sx={{ padding: '2px' }}
          onClick={props.onClick}
        >
          <CloseIcon
            sx={{
              fontSize: '12px',
            }}
            color="primary"
          />
        </IconButton>
        <Typography variant="body2" sx={{ color: '#000000DE' }}>{props.label}</Typography>
      </Box>
    </Box>
  )
}