import { ApiService } from "@/services/api";
import { useDispatch, useSelector } from '@/services/hooks';
import { useEffect, useState } from 'react';
import { getUserDetail } from '@/services/actions/userActions';
import { Plan } from '@/services/models';

import FreePlanPage from '@/components/UserPlan/Free';
import BronzePlanPage from '@/components/UserPlan/Bronze';

const UserPlan = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch])

  useEffect(() => {
    ApiService.fetchPlans().then((data) => {
      setPlans(data);
    });
  }, [])

  if (user?.is_free_plan) {
    return (
      <FreePlanPage
        plans={plans}
      />
    )
  }
  return (
    <BronzePlanPage
      plans={plans}
    />
  );
};

export default UserPlan;