import React, { useState, useEffect, useCallback } from "react";
import { ApiService } from "@/services/api";

import {
  Stack,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Swiper } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
import { CFStreamSignedItem } from "@/services/models";
import { useUserContext } from "@/contexts/user";
import LightStreamPlayer from "@/components/LightStreamPlayer/LightStreamPlayer";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { backToPlanPagePath } from "@/constants";

const LIMIT = 10;

const Videos: React.FC = () => {
  const navigateTo = useNavigate()
  const [CFStreamSignedItems, setCFStreamSignedItems] = useState<CFStreamSignedItem[]>([]);
  const { user, loading } = useUserContext();
  const [loadingVideos, setLoadingVideos] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLastScene, setIsLastScene] = useState(false);

  useEffect(() => {
    if (!loading && user && !user.is_free_plan) {
      ApiService.fetchCFStreamSignedItems(0).then((data) => {
        setCFStreamSignedItems(data);
        setLoadingVideos(false);
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
      });
    }
  }, [user, loading])

  const fetchNext = useCallback(() => {
    if (!user || user.is_free_plan) {
      return;
    }
    setLoadingMore(true);
    ApiService.fetchCFStreamSignedItems(page)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "click_load_more_video",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setPage((before) => {
          return before + 1;
        });
        setCFStreamSignedItems([...CFStreamSignedItems, ...data]);
        setLoadingMore(false);
      })
  }, [CFStreamSignedItems]);

  return (
    <AppBar title={<FormattedMessage id="videos.title" />}>
      <Stack direction="column" spacing={0} paddingY={3} paddingX={2} gap={0} >
        <Typography variant="subtitle1">
          <FormattedMessage id="videos.caption1" />
        </Typography>

        {loadingVideos && (
          <Typography variant="body2" color="text.primary" marginTop={2}>
            <FormattedMessage id="videos.loading" />
          </Typography>
        )}
      </Stack>
      <Grid container spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3} marginBottom={2}>
        {CFStreamSignedItems.map((video,) => (
          <Grid item xs={12} sm={12} key={video.token}>
            <Swiper
              modules={[Navigation, Pagination]}
              pagination
              navigation
              style={{
                marginTop: '16px'
              }}
            >
              <div>
                <Typography variant="body2" color="text.primary" marginTop={2}>
                  {video.title}
                </Typography>
                <LightStreamPlayer
                  src={video.token}
                  video_uid={video.video_uid}
                  thumbnail_start_sec={video.thumbnail_start_sec}
                />
              </div>
            </Swiper>
          </Grid>
        ))}

        {CFStreamSignedItems.length > 0 && !isLastScene && (
          <Stack
            marginTop={2}
            width="100%"
          >
            <Button
              onClick={fetchNext}
              variant="outlined"
              fullWidth
              disabled={loadingMore}
              sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
            >
              <FormattedMessage id="home.load_more" />
            </Button>
          </Stack>
        )}
      </Grid>

      {
        !user || user.is_free_plan && (
          <Stack
            display="flex"
            gap={2}
            padding="0 16px"
          >
            <Typography id="body2">
              <FormattedMessage id="videos.free_description" />
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                navigateTo(backToPlanPagePath)
              }}
            >
              <FormattedMessage id="videos.free_button" />
            </Button>
          </Stack>
        )
      }
    </AppBar>
  );
};

export default Videos;
