import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import {
  useDispatch,
  useSelector
} from '@/services/hooks';
import { FormattedMessage } from 'react-intl';
import { closeCharacterNewDialog } from '@/services/actions/commonActions';
import { useNavigate } from 'react-router-dom';

const CharacterNewDialog = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { character_new_dialog } = useSelector(state => state.common);
  const onClose = () => {
    dispatch(closeCharacterNewDialog());
  }
  return (
    <div>
      <Dialog
        open={character_new_dialog.open}
        onClose={onClose}
      >
        <DialogContent
          sx={{
            width: "100%",
            padding: '24px 16px 0',
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <Typography
              variant="body1"
              textAlign="center"
              fontWeight={500}
            >
              <FormattedMessage id="chracter_new_dialog.description" />
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                navigateTo('/characters/new');
                onClose();
              }}
            >
              <FormattedMessage id="chracter_new_dialog.button" />
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >
            <FormattedMessage id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default CharacterNewDialog;