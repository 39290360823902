/**
 * フィード、フィードメディアなどを含めたシーンのコンポーネント。
 * トップページや、キャラクターページから呼び出される。
 */
import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, Divider, IconButton, Stack } from "@mui/material";
import {
  FavoriteBorderOutlined,
  Favorite
} from '@mui/icons-material';

import { getRelativeTime } from "@/utils";
import { color, mainTheme } from "@/services/themes";
import { SwitchedScene } from "@/services/models";
import { ApiService } from "@/services/api";
import { openCharacterSwapDialog, openPlanDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import SwapIcon from "@/assets/SwapIcon.svg";
import { FormattedMessage } from "react-intl";
import Mask from "@/components/common/Mask";
import SwitchHint from "@/components/common/Hint/SwitchHint";
import TagManager from "react-gtm-module";
import SwitchedScenePlayer from "@/components/LightStreamPlayer/SwitchedScenePlayer";

interface Props {
  isTutorial?: boolean;
  index?: number;
  showDivider: boolean;
  onFavoriteChanged: (sceneId: number) => void;
  scene: SwitchedScene;
  showCharacter?: boolean;
}

export default function SceneSwitchedComp({
  isTutorial,
  index,
  showDivider,
  onFavoriteChanged = () => { },
  scene,
  showCharacter = true,
}: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((state) => state.user);

  const scrollDialog = useCallback((node: HTMLImageElement) => {
    if (!node) {
      return;
    }
    setTimeout(() => {
      node.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 500);
  }, []);

  const handleClickLike = (sceneId: number) => {
    setLoading(true);
    ApiService.sceneLike(sceneId)
      .then(() => {
        onFavoriteChanged(sceneId)
      }).catch((error) => {
        if (error.response.status === 403) {
          dispatch(openPlanDialog('favorite'));
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleClickUnlike = (sceneId: number) => {
    setLoading(true);
    ApiService.sceneUnlike(sceneId)
      .then(() => {
        onFavoriteChanged(sceneId)
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative',
        }}
      >
        {showCharacter && (
          <CardHeader
            sx={{ padding: "8px 0" }}
            avatar={
              (scene.character?.is_editable && !scene.character?.is_limited) ?
                <Link to={`/characters/${scene.character.id}`}>
                  <Avatar src={scene.character.url} />
                </Link> :
                <div>
                  <Avatar src={scene.character?.url} />
                </div>
            }
            key={scene.id}
            title={scene.character?.name}
            subheader={getRelativeTime(scene.posted_at)}
            disabled={!scene.character?.is_editable}
          />
        )}

        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          {scene.title}
        </Typography>

        <Swiper
          modules={[Navigation, Pagination]}
          pagination
          navigation
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "--swiper-navigation-size": "20px",
            "--swiper-theme-color": mainTheme.palette.primary.main,
            marginTop: '16px'
          }}
          key={scene.id}
        >

          <SwiperSlide style={{ padding: 0 }}>
            <SwitchedScenePlayer scene={scene} />
          </SwiperSlide>

        </Swiper>

        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap" marginTop={2} sx={{ justifyContent: scene.is_favorable ? 'space-between' : 'flex-end' }}>
          {scene.is_favorable && (
            <>
              <IconButton
                size="medium"
                color="inherit"
                sx={{ "&:hover": { color: mainTheme.palette.primary.main } }}
                onClick={() => scene.is_favorited ? handleClickUnlike(scene.id) : handleClickLike(scene.id)}
              >
                {loading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  scene.is_favorited ? (
                    <Favorite color="primary" />
                  ) : (
                    <FavoriteBorderOutlined />
                  )
                )}
              </IconButton>
            </>
          )}
          {!scene.is_favorable && (

            <Stack spacing={1} direction="row" alignItems="center" gap={1} >
              <Mask
                open={isTutorial && index === 0}
                zIndex={100}
              />
              <Button
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: isTutorial ? "tutorial_click_lets_switch_button" : "click_lets_switch_button",
                      user_id: user?.id,
                    }
                  });
                  dispatch(openCharacterSwapDialog(scene));
                }}
                size="small"
                color="inherit"
                sx={{
                  zIndex: isTutorial ? 101 : 1,
                  bgcolor: color.sceneEnjoyButton,
                  color: '#fff', "&:hover": { bgcolor: mainTheme.palette.primary.main },
                  postion: 'relative',
                  height: '30px',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                startIcon={<img
                  style={{
                    width: '18px',
                    display: 'inline-block',
                    marginLeft: '8px',
                  }}
                  src={SwapIcon}
                  alt="swap icon"
                  ref={isTutorial && index === 0 ? scrollDialog : undefined}
                />}
              >
                <FormattedMessage id="scene.enjoy" />
                {isTutorial && index === 0 ? <SwitchHint /> : null}
              </Button>
            </Stack>
          )}
        </Stack>
      </Card>
      {showDivider && <Divider sx={{ mt: 2 }} />}
    </>
  );
}
