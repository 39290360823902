import { axios } from '@/services/api';
import Axios from 'axios';
import { Dispatch } from 'redux';

export const getCharacters = (isTutorial: boolean) => async (dispatch: Dispatch) => {
  try {
    const url = isTutorial ? '/api/v1/tutorial/characters' : '/api/v1/characters';
    const { data } = await axios.get(url, {withCredentials: true});
    dispatch({ type: 'GET_CHARACTERS_SUCCESS', payload: data });
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'GET_CHARACTERS_FAIL', payload: e.message });
    }
  }
};
