import { getSession, setSession } from "@/utils"

const TUTORIAL_KEY = 'tutorial';
const TUTORIAL_SCENE_KEY = 'tutorial_scene';
const TUTORIAL_CHARACTER_KEY = 'tutorial_character';

export const TUTORIAL_STEP = {
  switch: 'switch_001',
  view: 'view_001',
  character: 'character_001',
  done: 'done',
};

// 基本的にreducerの初期にしか呼ばない
// reducerからtutorialのstepを取得してください
export const getTutorialStepSession = () => {
  return getSession(TUTORIAL_KEY);
}

// actionのsetTurorialStepを使うこと
export const setTutorialStepSession = (val: string) => {
  setSession(TUTORIAL_KEY, val);
}

export const setTutorialSelect = (characterId: number, sceneId: number | null) => {
  setSession(TUTORIAL_SCENE_KEY, String(sceneId));
  setSession(TUTORIAL_CHARACTER_KEY, String(characterId));
}

export const getTutorialSelect = () => {
  return {
    sceneId: Number(getSession(TUTORIAL_SCENE_KEY)) || 1,
    characterId: Number(getSession(TUTORIAL_CHARACTER_KEY)) || 1,
  }
}

export const initTutorialStep = () => {
  const step = getTutorialStepSession();
  if (!step) {
    setTutorialStepSession(TUTORIAL_STEP.switch)
  }
}

