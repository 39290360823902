import { Purchase } from "@/services/models"
import { isShowTags } from "@/components/AdTag/functions"

// LP～サンクスページの手前までの全ページに設置するタグ
export const GentamaBeforeThanks = () => {
  if (!isShowTags()) {
    return ''
  }
  return (
    <script>
      {`(function(){
var uqid = "23eb1696991dbb77";
var gid  = "21";
var a=document.createElement("script");
a.dataset.uqid=uqid;a.dataset.gid=gid;a.id="afadfpc-23eb1696991dbb77gid21-"+Date.now();
a.src="//ac.pointfun.jp/fpc/cookie_js.php?scriptId="+encodeURIComponent(a.id);
document.head.appendChild(a);
})();`}
    </script>
  )
}

type GentamaThanksProps = {
  purchase: Purchase
}

// サンクスページに設置するタグ
export const GentamaThanks = ({ purchase }: GentamaThanksProps) => {
  if (!isShowTags()) {
    return ''
  }
  if (purchase.purchase_type !== 'recurring') {
    return ''
  }
  const amount = 1700;  // 金額は固定で設定 本来は品の金額だが、特別に成果単価を設定
  return (
    <script>
      {`(function(){
var uqid   = "23eb1696991dbb77";
var gid    = "21";
var uid   = "${purchase.key}";
var uid2   = "";
var af   = "";
var pid    = "";
var amount = "${amount}";
var a=document.createElement("script");
a.src="//ac.pointfun.jp/ac/action_js.php";
a.id="afadaction-"+Date.now();
a.addEventListener("load",function(){(new fpcAction(a.id)).groupAction(gid, af, [uid, uid2], pid, amount, uqid)});
document.head.appendChild(a)})();`}
    </script>
  )
}
