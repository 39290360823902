import { backToPlanPagePath } from "@/constants";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks";
import { Dialog } from "@mui/material";


const LimitedTimeCampaignDialog = () => {
  const { open } = useSelector((state) => state.common.revival_payment_campaign_dialog);
  const dispatch = useDispatch();
  const navigate = useNavigateWithQueryAndHash();
  const handleClose = () => {
    dispatch({ type: 'CLOSE_REVIVAL_PAYMENT_CAMPAIGN_DIALOG' });
  }

  return (
    <Dialog open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          margin: '16px',
        }
      }}
    >
      <img
        src="/limited_time_dialog.png"
        alt="ブロンズプランに再登録で4000ポイントもらえる"
        onClick={() => {
          handleClose();
          navigate(backToPlanPagePath)
        }}
        style={{ cursor: 'pointer' }}
      />
    </Dialog>
  )
}

export default LimitedTimeCampaignDialog;