import React, { ReactElement } from "react";

import { Box, SxProps, Theme, Typography } from "@mui/material";

interface LabelProps {
  text: string | ReactElement;
  sx?: SxProps<Theme>;
}

const RoundedTag: React.FC<LabelProps> = ({ text, sx }) => {
  return (
    <Box
      // padding={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx
      }}
    >
      <Box
        sx={{
          // display: 'flex',
          // height: '32px',
          borderRadius: "100px",
          backgroundColor: "#F0629214",
          padding: "4px 16px",
        }}
      >
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  );
};

export default RoundedTag;
