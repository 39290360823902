import { Character } from "@/services/models";

interface UserState {
  characters: Character[]
}

const initialState: UserState = {
  characters: [],
};

export const characterReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case "GET_CHARACTERS_SUCCESS":
      return { ...state, characters: action.payload };
    default:
      return state;
  }
};
