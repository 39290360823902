import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import {
  Stack,
  Typography,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { openSnackbar } from "@/services/actions/commonActions.ts";
import { useDispatch } from "@/services/hooks.ts";
import { FormattedMessage } from "react-intl";
import { getUserDetail } from "@/services/actions/userActions.ts";

export default function EmailVerify() {
  const [message, setMessage] = useState<React.ReactNode>(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const afterSuccess = () => {
      dispatch(openSnackbar(<FormattedMessage id="mypage.register_mail.success" />));
      dispatch(getUserDetail());
      navigateTo('/mypage');
    }
    if (!token) {
      return;
    }

    ApiService.verifyEmail(token).then(() => {
      afterSuccess();
    }).catch((e) => {
      console.error("api error", e);
      if (e.response.status === 401) {
        setMessage(<FormattedMessage id="common.token.expired" />);
      } else {
        setMessage(<FormattedMessage id="common.token.invalid" />)
      }
    });
  }, []);

  if (!token) {
    navigateTo('/mypage');
    return;
  }

  return (
    <AppBar title="認証ページ">
      <Stack spacing={2} marginY={3} marginX={2} >
        {message && <Typography variant="body1">{message}</Typography>}
      </Stack>
    </AppBar >
  );
}

