import * as React from "react";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SwitchPoint from "@/assets/SwitchPoint.svg";

import {
  Avatar,
  Button,
  ButtonBase,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import HelpIcon from "@mui/icons-material/HelpOutline";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import { logout } from "@/services/actions/userActions";
import { useDispatch, useSelector } from '@/services/hooks';
import {
  drawerWidth,
  appBarHeight,
  appBarMiniHeight,
  isAdult,
  backToMypagePath,
} from '@/constants';
import { color, mainTheme } from "@/services/themes";
import { trackingUrlParam, getSession } from "@/utils.ts";
import { useMemo } from "react";

const menu = [
  {
    text: <FormattedMessage id="sidebar.home" />,
    icon: <HomeOutlinedIcon color="primary" />,
    link: "/",
  },
  {
    text: <FormattedMessage id="sidebar.mypage" />,
    icon: <AccountCircleOutlined color="primary" />,
    link: "/mypage",
    need_age_confirm: true,
  },
  {
    text: <FormattedMessage id="sidebar.videos" />,
    icon: <VideoLibraryOutlinedIcon color="primary" />,
    link: "/videos",
    need_age_confirm: true,
  },
  {
    text: <FormattedMessage id="sidebar.characters" />,
    icon: <PeopleOutlinedIcon color="primary" />,
    link: "/characters",
    need_age_confirm: true,
  },
  {
    text: <FormattedMessage id="sidebar.notice" />,
    icon: <NotificationsIcon color="primary" />,
    link: "/notice",
    is_notice: true,
  },
  {
    text: <FormattedMessage id="sidebar.help" />,
    icon: <HelpIcon color="primary" />,
    link: "/help",
  },

  {
    text: <FormattedMessage id="sidebar.other" />,
    icon: <FavoriteIcon color="primary" />,
    href: isAdult ? "/normal" : "/",
  }
];

interface Props {
  title: string | React.ReactElement;
  children?: React.ReactNode;
  isPrimary?: boolean;
  hidden?: boolean;
  isBlack?: boolean;
  showSignUpButton?: boolean;
}

export default function MenuAppBar(props: Props) {
  const { title, children, isPrimary, isBlack } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const navigateTo = useNavigate();
  const { formatMessage } = useIntl();
  const isConfirmed = !!getSession('age_confirm');
  const { detail } = useSelector((state) => state.user);
  const remain = _.get(detail, 'swap.item_remain', 0) + _.get(detail, 'swap.plan_remain', 0)

  const { notices } = useSelector((state) => state.common);
  const unreadCount = useMemo(() => {
    return _.filter(notices, { is_read: false }).length;
  }, [notices])

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      navigateTo("/demo");
    } catch (err) {
      console.log(err);
    }
  };

  const drawer = (
    <div>
      <Toolbar sx={{ display: { sm: 'none' } }} >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding>
          {user ? (
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <Avatar sx={{ width: '32px', height: '32px' }}><PersonIcon /></Avatar>
              </ListItemIcon>
              <ListItemText primary={<FormattedMessage id="sidebar.logged_in" />} sx={{ overflowWrap: 'anywhere' }} />
            </ListItemButton>
          ) : isConfirmed ? (
            <ListItemButton>
              <Link to="/users/login" onClick={handleDrawerToggle}>
                <Button variant="contained"><FormattedMessage id="sidebar.login" /></Button>
              </Link>
            </ListItemButton>
          ) : (<> </>)}
        </ListItem>
        {menu.map((item, index) => {
          if (!isConfirmed && item.need_age_confirm) return null;
          const queryWithHash = trackingUrlParam()
          return !item.link ? (
            <a
              key={index}
              href={`${item.href}${queryWithHash}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem key={item.link} disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: '40px' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </a>
          ) :
            (
              <Link
                key={index}
                to={`${item.link}${queryWithHash}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem key={item.link} disablePadding>
                  <ListItemButton
                    selected={item.link === (window.location.pathname.replace('/normal', '') || '/')}
                    sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <Box display="flex" alignItems="center">
                      <ListItemIcon sx={{ minWidth: '40px' }}>{item.icon}</ListItemIcon>
                      <ListItemText sx={{ marginRight: '2px' }} primary={item.text} />
                    </Box>
                    {item.is_notice && !!unreadCount && (
                      <Box
                        width="16px"
                        height="16px"
                        display="flex"
                        position="relative"
                        top="-4px"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          borderRadius: '50%',
                          backgroundColor: mainTheme.palette.primary.main,
                          color: 'white',
                          fontSize: '10px',
                        }}
                      >
                        {unreadCount}
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              </Link>
            )
        })}
        {user && (
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <LogoutIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={formatMessage({ id: "sidebar.logout", defaultMessage: "Logout" })} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </div>
  );

  const renderNoticeIcon = () => {
    if (!user) {
      return null;
    }
    return (
      <IconButton
        size="large"
        edge="start"
        aria-label="menu"
        sx={{
          paddingRight: '0px'
        }}
        onClick={() => {
          navigateTo('/notice')
        }}
      >
        <NotificationsIcon />
        {!!unreadCount && (
          <Box
            width="16px"
            height="16px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '50%',
              backgroundColor: mainTheme.palette.primary.main,
              position: 'absolute',
              right: '-6px',
              top: '8px',
              color: 'white',
              fontSize: '11px',
            }}
          >
            {unreadCount}
          </Box>
        )}
      </IconButton>
    );
  }

  return (
    <Box>
      <AppBar
        position="fixed"
        color={isPrimary ? 'primary' : 'secondary'}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: props.hidden ? 'none' : 'block',
          backgroundColor: isPrimary ? '#FFFFFF' : color.appBarBackground,
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            position="relative"
            sx={{
              flexGrow: 1,
              display: isPrimary ? 'flex' : 'block',
              justifyContent: {
                md: 'center',
                sm: 'flex-start',
                xs: 'flex-start',
              },
              alignItems: 'center',
            }
            }>
            {isPrimary ? (
              <>
                <Box
                  sx={{
                    width: {
                      xs: '36px',
                    },
                  }}>
                </Box>
                <Link
                  to={`/${window.location.search}`}
                  style={{
                    display: 'contents',
                  }}
                >
                  <img
                    src={isAdult ? "/logo@2x.png" : "slack-imgs 1.png"}
                    srcSet={isAdult ? "/logo@2x.png 2x,/logo@3x.png 3x" : "/slack-imgs 1@2x.png,/slack-imgs 1@3x.png"}
                    alt="FACESWITCH"
                    style={{
                      backgroundColor: isAdult ? 'none' : 'white',
                      width: isAdult ? '116px' : '90px',
                      height: isAdult ? '37.7px' : '30px',
                    }}
                  />
                </Link>
              </>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  marginLeft: {
                    xs: '48px',
                    sm: '0',
                  }
                }}
              >
                {title}
              </Typography>
            )}
          </Typography>

          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{
              display: { sm: 'none' },
              position: 'absolute',
              left: '16px',
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            display="flex"
            gap={1}
            alignItems="center"
            position="absolute"
            sx={{
              right: '16px'
            }}
          >
            {props.showSignUpButton ? (
              <Button
                size="small"
                variant="contained"
                sx={{ lineHeight: "22px" }}
                onClick={() => {
                  navigateTo('/users/signup')
                }}
              >
                <FormattedMessage id="header.signup" />
              </Button>
            ) : (!!isPrimary && !!isConfirmed && (
              <ButtonBase
                onClick={() => {
                  navigateTo(backToMypagePath)
                }}
              >
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  sx={{
                    borderRadius: '3px',
                    border: '1px solid rgba(0, 0, 0, 0.16)',
                    padding: '8px',
                    height: '36px',
                  }}
                >
                  <img src={SwitchPoint} alt="swap icon" width="20px" />
                  <Typography
                    variant="caption"
                    color='rgba(0, 0, 0, 0.6)'
                    fontFamily='ROBOTO'
                    fontSize={16}
                    fontWeight={500}
                    lineHeight={1}
                  >
                    {remain.toLocaleString()} pt
                  </Typography>
                </Box>
              </ButtonBase>)
            )}
            {renderNoticeIcon()}
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: "block",
              sm: "none"
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="persistent"
          anchor="left"
          sx={{
            display: {
              xs: "none",
              sm: "block"
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          position: { sm: 'absolute' },
          left: { sm: '240px' },
          marginTop: {
            sm: props.hidden ? 0 : `${appBarHeight}px`,
            xs: props.hidden ? 0 : `${appBarMiniHeight}px`,
          },
          background: isBlack ? '#121212' : 'transparent',
        }}
      >
        <Box
          sx={{
            maxWidth: { sm: '600px' },
            margin: { sm: '0 auto' },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box >
  );
}
