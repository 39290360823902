import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";

const Description = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      color="text.primary"
      fontSize="13px"
      fontWeight={400}
    >
      {children}
    </Typography>
  );
}

const LimitedTimeCampaign = () => {
  const [open, setOpen] = useState(false)

  return (
    <Box mt={3} mx={2} py={2.5} px={2} sx={{ background: "rgba(255, 246, 219, 1)" }}>
      <Box
        p={'7px'}
        mb={2}
        sx={{
          backgroundColor: 'rgba(216, 27, 96, 1)',
          position: 'relative',
          ':after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid rgba(216, 27, 96, 1)',
            bottom: '-7px',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        <Typography
          color="white"
          fontSize="18px"
          fontWeight={700}
          textAlign="center"
          fontFamily="Noto Sans CJK JP"
        >
          この通知が届いた方だけ！
        </Typography>
      </Box>
      <Typography fontSize="20px" fontWeight={700}>
        <Typography component='span' fontSize="20px" fontWeight={700} color="primary">
          11月30日
        </Typography>
        までにBronzeプランに再登録すると、
        <Typography component='span' fontSize="20px" fontWeight={700} color="primary">
          4,000ポイント
        </Typography>
        <Typography component='span' fontSize="14px" fontWeight={700} color="primary">
          {" "}(通常2,000pt + ボーナス2,000pt){" "}
        </Typography>
        もらえる！
      </Typography>
      <Box py={1} px={3}>
        <img src="/limited_time_plan.png" alt="2000ポイントお得" width="100%" />
      </Box>
      {open && (
        <>
          <Stack gap={1} my={2.5}>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="13px"
                fontWeight={700}
              >
                ＜内容＞
              </Typography>
              <Description>
                今だけ！キャンペーン期間中にブロンズプランに再登録すると、Switchポイントを通常の2倍（通常2,000pt + ボーナス2,000pt）獲得できる。
              </Description>
            </Stack>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="13px"
                fontWeight={700}
              >
                ＜ボーナスポイントの付与条件＞
              </Typography>
              <Description>
                ・キャンペーン期間中にブロンズプランに新規登録もしくは再登録する
                <br />
                ・キャンペーンのお知らせを受け取っている
              </Description>
            </Stack>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="13px"
                fontWeight={700}
              >
                ＜期間＞
              </Typography>
              <Description>
                2024/11/30まで
              </Description>
            </Stack>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="13px"
                fontWeight={700}
              >
                ＜ポイント付与＞
              </Typography>
              <Description>
                キャンペーン終了日から2週間以内に付与
              </Description>
            </Stack>
          </Stack>
          <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.3)' }} />
          <Stack gap={2} my={2.5}>
            <Description>
              より皆様にFACESWITCHを楽しんでいただくため、 決済復旧と同時に様々な機能アップデートをリリースしております。
            </Description>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="12px"
                fontWeight={700}
              >
                アップデート 1
              </Typography>
              <Typography
                color="text.primary"
                fontSize="13px"
                fontWeight={700}
              >
                お試し画像スイッチ
              </Typography>
              <Description>
                毎日10回ずつ無料！動画スイッチの前にスイッチの仕上がり具合を静止画にて確認できる機能です。
              </Description>
            </Stack>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="12px"
                fontWeight={700}
              >
                アップデート 2
              </Typography>
              <Typography
                color="text.primary"
                fontSize="13px"
                fontWeight={700}
              >
                絞り込みタグのアップデート
              </Typography>
              <Description>
                絞り込み機能がもっと使いやすくなりました。
              </Description>
            </Stack>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="12px"
                fontWeight={700}
              >
                アップデート 3
              </Typography>
              <Typography
                color="text.primary"
                fontSize="13px"
                fontWeight={700}
              >
                動画作品大量追加
              </Typography>
              <Description>
                毎週金曜日の定期新作追加に加え、大量に動画作品を追加しました。
              </Description>
            </Stack>
            <Stack gap={0.5}>
              <Typography
                color="primary"
                fontSize="12px"
                fontWeight={700}
              >
                アップデート 4
              </Typography>
              <Typography
                color="text.primary"
                fontSize="13px"
                fontWeight={700}
              >
                ソーシャルログイン
              </Typography>
              <Description>
                LINEとXのアカウントを利用してログインができるようになりました。
              </Description>
            </Stack>
            <Stack gap={1.5}>
              <Typography
                color="primary"
                fontSize="14px"
                fontWeight={700}
              >
                追加アップデート予定
              </Typography>
              <Description>
                アップロードした顔写真に合わせて、相性の良い動画がオススメされる機能を準備中！
              </Description>
              <Description>
                スイッチした動画に評価を付けることで、動画の精度が上がりやすくなる機能を準備中！
              </Description>
            </Stack>
          </Stack>

        </>
      )}
      <Button
        variant="outlined"
        fullWidth
        onClick={() => setOpen(v => !v)}
        sx={{
          borderRadius: "40px",
          background: "rgb(255, 255, 255)",
          ":hover": {
            background: "rgb(255, 255, 255)",
          }
        }}
      >
        {open
          ? <>詳細を閉じる<KeyboardArrowUp /></>
          : <>詳細を見る<KeyboardArrowDown /></>
        }
      </Button>
    </Box>
  );
}
export default LimitedTimeCampaign;