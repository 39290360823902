import SceneImage from "@/components/Home/Switched/SceneImage";
import { ApiService } from "@/services/api";
import { useSelector } from "@/services/hooks";
import { SwappedImageHistory } from "@/services/models";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const LIMIT = 50;
export default function SwitchedImage() {
  const { user } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);

  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [sceneImages, setSceneImages] = useState<SwappedImageHistory[]>([]);

  const init = () => {
    setPage(1);
    fetchSceneImages();
  }

  const fetchSceneImages = () => {
    const option = { page: 0 };
    ApiService.getSwitchedImages(option).then((data) => {
      console.log(data);
      if (data.length < LIMIT) {
        setIsLastScene(true);
      }
      setSceneImages(data);
    })
  }

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = { page };
    ApiService.getSwitchedImages(option).then((data) => {
      setSceneImages((before) => {
        return [...before, ...data];
      });
      if (data.length < LIMIT) {
        setIsLastScene(true);
      }
    })

  }, [sceneImages, page]);

  useEffect(() => {
    init()
  }, [user])

  return (
    <Stack direction="column" spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3}>
      {sceneImages?.map((item, index) => (
        <Box key={`image_${index}`}>
          <SceneImage
            scene_image_history={item}
          />
        </Box>
      ))}

      {sceneImages.length > 0 && !isLastScene && (
        <Stack
          marginTop={2}
          width="100%"
        >
          <Button
            onClick={fetchNext}
            variant="outlined"
            fullWidth
            disabled={loadingMore}
            sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
          >
            <FormattedMessage id="home.load_more" />
          </Button>
        </Stack>
      )}

      {
        !sceneImages.length && (
          <Box sx={{ width: '100%', textAlign: 'center' }} color="text.secondary">
            <FormattedMessage id="home.empty_switched_image" />
          </Box>
        )
      }
    </Stack>
  )
}