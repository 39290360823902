import { Box, LinearProgress, Typography } from "@mui/material";
import { useState, useEffect } from "react";

type PropTypes = {
  value: number;
}
export default function ProgressBar(props: PropTypes) {
  const { value } = props;

  const DotsAnimation = () => {
    // 3つのドットをアニメーションさせる
    const [dots, setDots] = useState(0)
    useEffect(() => {
      const interval = setInterval(() => {
        setDots((d) => (d + 1) % 4)
      }, 800)
      return () => clearInterval(interval)
    }, [])
    return (
      <Box width="13px">
        {".".repeat(dots)}
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      height="16px"
      sx={{
        width: '100%',
      }}
    >
      <LinearProgress
        variant="determinate"
        value={value}
        color="primary"
        sx={{
          width: '100%',
          "&:after": {
            position: "absolute",
            content: "''",
            display: "inline-block",
            width: "100%",
            height: "100%",
            backgroundImage: "linear-gradient(60deg, transparent, transparent 30%, rgba(255, 255, 255, 70%) 50%, transparent 51%, transparent)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "300% 100%",
            animationName: "scroll-right",
            animationDuration: "2.8s",
            animationIterationCount: "infinite",
            animationTimingFunction: "cubic-bezier(0.22, 1, 0.36, 1)",
            animationDelay: "1s",
            animationFillMode: "backwards",
            "@keyframes scroll-right": {
              "0%": { backgroundPosition: "100% 0" },
              "50%": { backgroundPosition: "0 0" },
              "100%": { backgroundPosition: "-100% 0" },
            }
          },
        }}
      />
      <Typography
        fontSize="11px"
      >
        <Box
          sx={{ minWidth: '43px' }}
          display="flex"
          justifyContent="right"
          alignItems="center"
        >
          {value}%
          <DotsAnimation />

        </Box>
      </Typography>
    </Box>
  )
}