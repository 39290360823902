import { Stack, Typography } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { FormattedMessage } from "react-intl";

type propTypes = {
  onClick: () => void;
}

export default function SwitchViewHint(props: propTypes) {
  const { onClick } = props;
  return (
    <Stack
      position="absolute"
      padding="14px 24px"
      spacing={1}
      width="300px"
      sx={{
        cursor: 'pointer',
        backgroundColor: '#FFF',
        top: '70px',
        left: 'calc(50% - 150px)',
        zIndex: 101,
        borderRadius: '16px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-20px',
          left: 'calc(50%)',
          marginLeft: '-10px',
          border: '10px solid transparent',
          borderBottom: '10px solid #FFFFFF',
        }
      }}
      onClick={onClick}
    >

      <Typography
        variant="body1"
        color="rgba(0, 0, 0, 0.60)"
        sx={{
          fontSize: '10px',
          lineHeight: 1.5,
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <TipsAndUpdatesIcon fontSize="small" sx={{ color: "#FFD54F" }} />
        <FormattedMessage id="hint.switch.how_to_use" />
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
        }}
      >
        <FormattedMessage id="hint.switch_view.lets" />
      </Typography>

      <Stack
        direction="row"
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="start-flex"
      >
        <Typography variant="caption" color="rgba(0, 0, 0, 0.60)" >
          <FormattedMessage id="hint.switch_view.caption" />
        </Typography>
      </Stack>
    </Stack >
  )
}