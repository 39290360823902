/**
 * フィード、フィードメディアなどを含めたシーンのコンポーネント。
 * トップページや、キャラクターページから呼び出される。
 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Divider, Button, Stack } from "@mui/material";

import { color, mainTheme } from "@/services/themes";
import { CFStreamSignedItem } from "@/services/models";
import { openCharacterSwapDialogWithSample } from "@/services/actions/commonActions";
import { useDispatch } from '@/services/hooks';
import SwapIcon from "@/assets/SwapIcon.svg";
import { FormattedMessage } from "react-intl";
import LightStreamPlayer from "@/components/LightStreamPlayer/LightStreamPlayer";

interface Props {
  video: CFStreamSignedItem;
  showDivider: boolean;
}

export default function SceneSampleComp(props: Props) {
  const dispatch = useDispatch();
  const video = props.video;
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative'
        }}
      >

        {/*showCharacter && (
          <CardHeader
            sx={{ padding: "8px 0" }}
            avatar={
              scene.character.owner_id === user?.id ?
                <Link to={`/characters/${scene.character.id}`}>
                  <Avatar src={scene.character.url} />
                </Link> :
                <div>
                  <Avatar src={scene.character.url} />
                </div>
            }
            key={scene.id}
            title={scene.character.name}
            {...(scene.point !== 0 ? { subheader: getRelativeTime(scene.posted_at) } : {})}
            disabled={scene.character.owner_id !== user?.id}
          />
          )*/}

        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          {video.title}
        </Typography>


        <Swiper
          modules={[Navigation, Pagination]}
          pagination
          navigation
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "--swiper-navigation-size": "20px",
            "--swiper-theme-color": mainTheme.palette.primary.main,
            marginTop: '16px'
          }}
          key={video.video_uid}
        >

          <SwiperSlide style={{ padding: 0 }}>
            <LightStreamPlayer
              src={video.token}
              video_uid={video.video_uid}
              thumbnail_start_sec={video.thumbnail_start_sec}
            />
          </SwiperSlide>

        </Swiper>

        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap" marginTop={2} sx={{ justifyContent: 'flex-end' }}>
          <Stack spacing={1} direction="row" alignItems="center" gap={1} >
            <Button
              onClick={() => {
                dispatch(openCharacterSwapDialogWithSample(video));
              }}
              size="medium"
              color="inherit"
              sx={{
                zIndex: 1,
                bgcolor: color.sceneEnjoyButton,
                color: '#fff', "&:hover": { bgcolor: mainTheme.palette.primary.main },
                postion: 'relative',
                height: '30px',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              startIcon={<img
                style={{
                  width: '18px',
                  display: 'inline-block',
                  marginLeft: '8px',
                }}
                src={SwapIcon}
                alt="swap icon"
              />}
            >
              <FormattedMessage id="scene.enjoy" />
            </Button>
          </Stack>
        </Stack>
      </Card>

      {props.showDivider && <Divider sx={{ mt: 2 }} />}
    </>
  );
}
