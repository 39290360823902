/**
 * 新規マイキャラクター編集ページ
 */
import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import {Character, Genre} from "@/services/models";
import { openSnackbar } from "@/services/actions/commonActions";
import { useDispatch } from "@/services/hooks";
import CharacterDeleteDialog from "@/components/Dialog/character_delete";
import { FormattedMessage } from "react-intl";
import CharacterGenreList from "@/components/CharacterGenre/CharacterGenreList.tsx";

export default function CharacterNew() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { characterId } = useParams<{ characterId: string }>();
  const [character, setCharacter] = useState<Character | null>(null);
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [genres, setGenres] = useState<Genre[]>([])
  const [selectedGenres, setSelectedGenres] = useState<number[]>([]);

  useEffect(() => {
    console.log("useEffect status : ", status);
    ApiService.getCharacter(Number(characterId))
      .then((data) => {
        setCharacter(data);
        setSelectedGenres(data.genre_ids)
        setName(data.name);
      })
      .catch((error) => console.error("Error:", error));
  }, [characterId]);

  useEffect(() => {
    ApiService.fetchGenres().then((data) => {
      setGenres(data);
    }).catch(
      (error) => console.error("Error:", error));
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = await ApiService.updateCharacter(character?.id, name, selectedGenres);
      setCharacter(data);
      dispatch(openSnackbar(<FormattedMessage id="character.edit.update_complete" />));
    } catch (error) {
      console.error("update error", error);
      dispatch(openSnackbar(<FormattedMessage id="character.edit.error" />));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await ApiService.deleteCharacter(character?.id);
      dispatch(openSnackbar(<FormattedMessage id="character.edit.delete_complete" />));
      navigateTo('/characters');
    } catch (error) {
      console.error("delete error", error);
      dispatch(openSnackbar(<FormattedMessage id="character.edit.error" />));
    } finally {
      setLoading(false);
    }
  }

  const handleGenreClick = (genre: number) => {
    setSelectedGenres(prevSelected =>
      prevSelected.includes(genre)
        ? prevSelected.filter(item => item !== genre)
        : [...prevSelected, genre]
    );
  };

  return (
    <AppBar title={<FormattedMessage id="character.edit.title" />}>

      <Stack spacing={2} margin={2}>
        <Stack alignItems="center" gap={3} direction="column">

          {character?.url && (
            <div>
              <img
                src={character?.url}
                alt="キャラクター画像"
                style={{ width: "100%", marginTop: "20px" }}
              />
            </div>
          )}

          <Box
            display='flex'
            flexWrap='wrap'
            gap="6px"
          >
            {
              <CharacterGenreList
                genres={genres}
                handleGenreClick={handleGenreClick}
                selectedGenres={selectedGenres}
              />
            }
          </Box>
          <TextField
            fullWidth
            required
            id="outlined-required"
            label={<FormattedMessage id="character.edit.input_name" />}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Button
            fullWidth
            variant="contained"
            disabled={!name || loading}
            sx={{
              marginTop: '16px',
              padding: '8px 22px',
              maxWidth: '284px',
            }}
            onClick={handleSubmit}
          >
            <span><FormattedMessage id="character.edit.complete" /></span>
          </Button>

          <Button
            fullWidth
            variant="outlined"
            disabled={loading}
            sx={{
              marginTop: '-8px',
              maxWidth: '284px',
            }}
            onClick={() => { setIsOpenDeleteDialog(true); }}
          >
            <span><FormattedMessage id="character.edit.delete_character" /></span>
          </Button>

          <Button
            disabled={loading}
            variant="text"
            sx={{
              marginTop: '-8px',
            }}
            onClick={() => {
              navigateTo(-1)
            }}
          >
            <span><FormattedMessage id="character.edit.cancel" /></span>
          </Button>
        </Stack>

      </Stack>

      <CharacterDeleteDialog
        open={isOpenDeleteDialog}
        onExecute={async () => {
          await handleDelete();
          setIsOpenDeleteDialog(false);
        }}
        onCancel={() => {
          setIsOpenDeleteDialog(false);
        }}
      />
    </AppBar>
  );
}
