import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import {
  Stack,
  Typography,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { getUserInfo } from "@/services/actions/userActions";
import { useDispatch } from "@/services/hooks";
import TagManager from "react-gtm-module";
import { initTutorialStep } from "@/services/tutorial.ts";
import { User } from "@/services/models";
import { MonetrackLeadThanks } from "@/components/AdTag/monetrack";
import { DEBUG_KEY } from "@/settings";
import { FormattedMessage } from "react-intl";

export default function Verify() {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<React.ReactNode>(null);
  const [user, setUser] = useState<User | null>(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isDebug = searchParams.get('debug') === DEBUG_KEY; // トークンの期限を無視して完了画面を表示するデバッグモード
  const navigateTo = useNavigate();

  useEffect(() => {
    const afterSuccess = (user: User) => {
      setMessage(<FormattedMessage id="user.signup.success" />)
      setUser(user);
      dispatch(getUserInfo()).then((me) => {
        if (me?.is_free_plan) {
          initTutorialStep();
        }
      })
      setTimeout(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: "mail_verify",
          }
        });
        navigateTo('/');
      }, 1000);
    }
    if (!token) {
      return;
    }
    if (isDebug) {
      dispatch(getUserInfo()).then((me) => {
        afterSuccess(me);
      })
      return;
    }
    ApiService.verify(token).then((response) => {
      afterSuccess(response.user);
    }).catch((e) => {
      console.error("api error", e);
      if (e.response.status === 401) {
        setMessage(<FormattedMessage id="common.token.expired" />);
      } else {
        setMessage(<FormattedMessage id="common.token.invalid" />)
      }
    });
  }, []);

  if (!token) {
    navigateTo('/');
    return;
  }

  return (
    <AppBar title="認証ページ">
      <Stack spacing={2} marginY={3} marginX={2} >
        {message && <Typography variant="body1">{message}</Typography>}
        {user && <MonetrackLeadThanks user={user} />}
      </Stack>
    </AppBar >
  );
}

