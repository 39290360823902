import {
  Stack,
  Typography
} from "@mui/material";
import { color } from "@/services/themes";
import React from "react";

type Props = {
  label: React.ReactNode,
  description: React.ReactNode,
  plan_remain: number,
  item_remain?: number,
  plan_limit: number,
  disableLimit?: boolean,
  displayCurrent?: boolean,
};
export default function PointItem(props: Props) {
  const { displayCurrent, description, label, plan_remain, item_remain, plan_limit, disableLimit } = props;
  return (
    <div
      style={{
        display: 'flex',
        padding: '0 2px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
      }}
    >
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        direction="row"
        flexWrap="wrap"
      >
        <Typography
          variant="subtitle2"
          sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
        >
          {label}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: 'rgba(0, 0, 0, 0.60)' }}
        >
          {description}
        </Typography>
      </Stack>
      <div
        style={{
          display: 'flex',
          padding: 0,
          justifyContent: 'center',
          alignItems: 'flex-end',
          gap: '4px',
        }}
      >
        <Typography
          sx={{
            color: color.main,
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.15px',
          }}
        >
          {
            displayCurrent
              ? (plan_limit || 0) - (plan_remain || 0)
              : (plan_remain || 0)
          }
        </Typography>

        {
          !!item_remain &&
          <>
            +
            <Typography
              sx={{
                color: '#0288D1',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.15px',
              }}
            >
              {(item_remain)}
            </Typography>
          </>
        }

        {!disableLimit &&
          <Typography
            sx={{
              color: 'rgba(0, 0, 0, 0.60)',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '20px',
              letterSpacing: '0.15px',
              whiteSpace: 'nowrap',
            }}
          >
            / {plan_limit || 0}
          </Typography>
        }
      </div>
    </div>
  )
}