import _ from 'lodash';

type ClickPaymentItemType = {
  user_id: number,
  email: string,
  price: number,
  item_id: number,
  order_num: number,
};

export const clickPaymentItem = (props: ClickPaymentItemType) => {
  const { user_id, email, price, item_id, order_num } = props;
  const form = document.createElement('form');
  form.action = 'https://secure.tc-service.net/quick_screen';
  form.method = 'POST';

  const list = [
    // techcoinが発行する番組コード
    { name: 'clientip', value: import.meta.env.VITE_TECHCOIN_CLIENTIP },
    // ユーザーID など会員を特定できるもの
    { name: 'sendid', value: user_id },
    // 決済金額(デフォルト JPY) 先頭に$をつければUSD(整数4桁+小数点以下2桁)
    { name: 'price', value: price },
    // 番組側で決済を識別する一意な値等(任意)
    { name: 'order_num', value: order_num },
    { name: 'email', value: email },
    // 固定
    { name: 'card_num', value: '9999999999999992' },
    { name: 'kind', value: 'item' },
    { name: 'free_param', value: item_id },
  ]

  for (const item of list) {
    const q = document.createElement('input');
    q.type = 'hidden'
    q.value = item.value?.toString() || '';
    q.name = item.name;
    form.appendChild(q);
  }

  document.body.appendChild(form);
  form.submit();
}



type ClickPaymentPlanType = {
  user_id: number,
  email: string,
  price: number,
  plan_id: number,
  plan_name: string,
  order_num: number,
};

export const clickPaymentPlan = (props: ClickPaymentPlanType) => {
  const { user_id, email, price, plan_id, plan_name, order_num } = props;
  const form = document.createElement('form');
  form.action = 'https://secure.tc-service.net/quick_screen';
  form.method = 'POST';

  const list = [
    // techcoinが発行する番組コード
    { name: 'clientip', value: import.meta.env.VITE_TECHCOIN_CLIENTIP },
    // ユーザーID など会員を特定できるもの
    { name: 'sendid', value: user_id },
    // 決済金額(デフォルト JPY) 先頭に$をつければUSD(整数4桁+小数点以下2桁)
    { name: 'price', value: price },
    // 番組側で決済を識別する一意な値等(任意)
    { name: 'order_num', value: order_num },
    { name: 'email', value: email },
    // 固定
    { name: 'card_num', value: '9999999999999992' },
    // 自由項目
    { name: 'kind', value: 'plan' },
    { name: 'free_param', value: plan_id },

    // リカーリング商品名。表示する場合は送信して下さい。 cycle_type がリカーリング商品IDの場合はリカーリング商品登録時のリカーリング商 品名に上書きされるため不要です。
    { name: 'product_name', value: `${plan_name}プラン` },
  ]

  for (const item of list) {
    const q = document.createElement('input');
    q.type = 'hidden'
    q.value = item.value?.toString() || '';
    q.name = item.name;
    form.appendChild(q);
  }

  document.body.appendChild(form);
  form.submit();
}

