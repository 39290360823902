import { User } from '@/services/models';
import React from 'react';

type ContextType = {
  user: User | null,
  loading: boolean,
}
const UserContext = React.createContext<ContextType>({ user: null, loading: false, });

export const UserContextProvider = UserContext.Provider;
export const useUserContext = () => React.useContext(UserContext);