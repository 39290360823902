import { Stack, Typography } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { FormattedMessage } from "react-intl";

export default function CharacterHint() {
  return (
    <Stack
      position="absolute"
      padding="14px 24px"
      spacing={1}
      width="300px"
      sx={{
        backgroundColor: '#FFF',
        top: '75px',
        left: 'calc(50% - 165px)',
        zIndex: 101,
        borderRadius: '16px',
        border: 'solid 1px #CCC',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-10px',
          left: 'calc(50%)',
          marginLeft: '-10px',
          width: '20px',
          height: '20px',
          backgroundColor: '#FFF',
          borderLeft: '1px solid #CCC',
          borderTop: '1px solid #CCC',
          transform: 'rotate(45deg)',
        }
      }}
    >

      <Typography
        variant="body1"
        color="rgba(0, 0, 0, 0.60)"
        sx={{
          fontSize: '10px',
          lineHeight: 1.5,
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <TipsAndUpdatesIcon fontSize="small" sx={{ color: "#FFD54F" }} />
        <FormattedMessage id="hint.switch.how_to_use" />
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
        }}
      >
        <FormattedMessage id="hint.character.lets" />
      </Typography>

      <Stack
        direction="row"
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="start-flex"
      >
        <Typography variant="caption" color="rgba(0, 0, 0, 0.60)" >
          <FormattedMessage id="hint.character.caption" />
        </Typography>
      </Stack>
    </Stack >
  )
}
