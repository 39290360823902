import { TypedUseSelectorHook, useDispatch as ud, useSelector as us } from 'react-redux'
import type { RootState, AppDispatch } from '@/services/store'
import { useSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { trackingUrlParam } from '@/utils';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useDispatch: DispatchFunc = ud
export const useSelector: TypedUseSelectorHook<RootState> = us

export const useUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getUrlParam = (key: string) => searchParams.get(key);
  const setUrlParam = (key: string, value: string) => {
    if (!_.isEmpty(value)) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams, { replace: true });
  };
  const unsetUrlParam = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams, { replace: true });
  };
  const hasUrlParam = (key: string) => {
    const keys = Array.from(searchParams.keys());
    return _.includes(keys, key);
  };
  return { getUrlParam, setUrlParam, unsetUrlParam, hasUrlParam };
}

export const useNavigateWithQueryAndHash = () => {
  const navigate = useNavigate();
  const queryWithHash = trackingUrlParam();  // 既存のクエリパラメータとハッシュを保持

  const navigateTo = (path: string) => {
    // 現在のクエリパラメータとハッシュを取得
    const currentUrlParams = new URLSearchParams(queryWithHash ? queryWithHash.replace(/^\?/, '').split('#')[0] : '');
    const currentHash = queryWithHash.split('#')[1] || '';

    // 新しいパスのURLを処理
    const [newPath, newQueryString] = path.split('?');
    const newUrlParams = new URLSearchParams(newQueryString || '');

    // 新しいURLと現在のURLのクエリパラメータをマージ
    newUrlParams.forEach((value, key) => {
      currentUrlParams.set(key, value);
    });

    const finalQueryString = currentUrlParams.toString();
    const finalPath = `${newPath}${finalQueryString ? `?${finalQueryString}` : ''}${currentHash ? `#${currentHash}` : ''}`;

    navigate(finalPath);
  };

  return navigateTo;
};
