import { backToPlanPagePath } from "@/constants";
import { useSelector } from "@/services/hooks";
import { isLimitedCampaignPeriod } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom"

const RevivalPaymentBanner = ({
  showText = false,
  textSize = "medium",
  onClick,
}: { showText?: boolean, textSize?: 'medium' | 'small', onClick?: () => void }) => {
  const { user, detail } = useSelector((state) => state.user);
  const fontSize = textSize === 'small' ? '12px' : '14px';
  const margin = textSize === 'small' ? "8px" : "10px";
  return user?.is_free_plan && detail?.is_compensation_target && (<Stack>
    {showText && isLimitedCampaignPeriod() &&
      <Typography fontWeight={700} fontSize={fontSize} color="rgba(51, 51, 51, 1)" sx={{ textAlign: 'center', marginBottom: margin }}>
        今だけ！再登録で
        <Typography component="span" color="rgba(240, 98, 146, 1)" fontWeight={700} fontSize={fontSize}>4,000pt</Typography>
        もらえるキャンペーン中！
      </Typography>
    }
    <Link to={backToPlanPagePath} onClick={() => onClick && onClick()}>
      <img src="/revival_payment_banner.png" alt="決済復活キャンペーンバナー" style={{ width: '100%' }} />
    </Link>
  </Stack>
  )
}

export default RevivalPaymentBanner