import { drawerWidth } from '@/constants';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type PropType = {
  onExecute: () => void,
  onCancel: () => void,
  open: boolean,
}
export default function CharacterDeleteDialog(props: PropType) {
  const {
    onExecute, onCancel, open,
  } = props;

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: '100%',
        },
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          sm: { marginLeft: `${drawerWidth}px` }
        }
      }}
    >
      <DialogContent>
        <Stack>
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              disabled
            >
              <InfoOutlinedIcon width="30px" color="error" />
            </IconButton>
            <Typography variant="subtitle1">
              <FormattedMessage id="character_delete.title" />
            </Typography>
          </Stack>
          <Stack
            margin="16px 0"
          >
            <Typography variant="body2">
              <FormattedMessage id="character_delete.message" />
            </Typography>
          </Stack>

          <Stack
            padding={1}
            sx={{
              background: '#F2F2F2',
            }}
          >
            <Typography variant="body2">
              ・<FormattedMessage id="character_delete.detail1" />
            </Typography>
            <Typography variant="body2">
              ・<FormattedMessage id="character_delete.detail2" />
            </Typography>
            <Typography variant="body2">
              ・<FormattedMessage id="character_delete.detail3" />
            </Typography>
          </Stack>

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <Typography sx={{ color: '#00000099' }}>
            <FormattedMessage id="character_delete.cancel" />
          </Typography>
        </Button>
        <Button onClick={onExecute}><FormattedMessage id="character_delete.delete" /></Button>
      </DialogActions>
    </Dialog>
  );
}