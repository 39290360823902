import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from '@/services/reducers/userReducer';
import { commonReducer } from '@/services/reducers/commonReducer';
import { sceneReducer } from '@/services/reducers/sceneReducer';
import { characterReducer } from '@/services/reducers/characterReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    common: commonReducer,
    scene: sceneReducer,
    character: characterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;