import { ApiService } from "@/services/api";
import { useSelector } from "@/services/hooks";
import { Item } from "@/services/models";
import { Stack, Box, Button } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

type PropType = {
  disabledButton?: boolean;
};

const OnetimeItemBanner = (props: PropType) => {
  const { disabledButton } = props;
  const [items, setItems] = useState<Item[]>([]);

  const navigateTo = useNavigate();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    ApiService.fetchItems().then((data) => {
      setItems(data);
    })
  }, [user]);

  const hasItem = useMemo(() => {
    return _.some(items, (item) => {
      return item.purchase_limit === 1 && item.price === '2.00';
    })
  }, [items]);

  if (!hasItem) {
    return null;
  }
  return (
    <Stack mt={2} paddingX={2}>
      <Stack>
        <img style={{ width: '100%' }} src="/home/item_ban_old@2x.png" srcSet="/home/item_ban_old@3x.png 2x,/home/item_ban_old@3x.png 3x" alt="banner" />
      </Stack>
      {!disabledButton && (
        <Box
          width="100%"
          padding="4px 17px 18.58px"
          sx={{
            backgroundColor: '#0288D1',
          }}
        >
          <Button
            fullWidth
            color="onetine_item_button"
            variant="contained"
            onClick={() => {
              navigateTo('/users/plan')
              TagManager.dataLayer({
                dataLayer: {
                  event: "move_plan_from_item_button",
                  user_id: user?.id,
                }
              });
            }}
            sx={{
              fontWeight: 700,
              background: 'linear-gradient(to bottom, #FFFFFF, #FFFA8C)',
              ':hover': {
                opacity: 0.8,
              }
            }}
          >
            <FormattedMessage id="home.onetime_item.button" />
          </Button>
        </Box>
      )}
    </Stack>
  )
}
export default OnetimeItemBanner;