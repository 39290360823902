import CanBronzePlan from "@/components/common/Plan/CanBronzPlan";
import { backToMypagePath, backToPlanPagePath, drawerWidth } from "@/constants";
import { closeSwitchedFeedbackDialog } from "@/services/actions/commonActions";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks";
import { color, mainTheme } from "@/services/themes";
import { ControlPointDuplicate, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import PresentBox from "@/assets/PresentBox.svg";
import { ApiService } from "@/services/api";
import { FeedbackType } from "@/services/models";
import { getUserDetail } from "@/services/actions/userActions";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";

type TipBoxProps = {
  tipNumber: number
  title: React.ReactNode
  children: React.ReactNode
}
const TipBox = ({
  tipNumber,
  title,
  children,
}: TipBoxProps) => {
  return (
    <Stack
      justifyContent="center"
      gap={1}
      border={`1px solid rgba(204, 204, 204, 1)`}
      padding={1.5}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Stack
          border="solid"
          borderRadius="4px"
          borderColor={mainTheme.palette.primary.main}
          width="21px"
          height="22px"
          flexShrink={0}
          textAlign="center"
          lineHeight="22px"
          fontSize="14px"
          fontWeight={700}
          color={mainTheme.palette.primary.main}
        >
          {tipNumber}
        </Stack>
        <Typography fontSize="13px" fontWeight={500}>
          {title}
        </Typography>
      </Stack>
      <Stack justifyContent="center" direction="row">
        {children}
      </Stack>
    </Stack>
  )
}

type SpeechBubbleProps = {
  onClick: () => void
  type: 'good' | 'bad'
  disabled: boolean
}
const SpeechBubble = ({ onClick, type, disabled }: SpeechBubbleProps) => {
  const color = type === 'good' ? mainTheme.palette.primary.main : mainTheme.palette.grey[500];
  return (
    <ButtonBase sx={{ overflow: 'visible' }} onClick={onClick} disabled={disabled}>
      <Paper elevation={2} sx={{ padding: '20px 25px', borderRadius: "8px" }}>
        <Box
          sx={{
            backgroundColor: color,
            padding: '8px 16px',
            borderRadius: '8px',
            position: 'relative',
            whiteSpace: 'nowrap',
            ':before': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              marginLeft: '-4px',
              border: '4px solid transparent',
              borderTop: `4px solid black`,
              borderTopColor: color,
            }
          }}>
          <Typography sx={{
            font: 'Noto Sans JP',
            color: 'white',
            fontSize: '13px',
            fontWeight: 700,
            lineHeight: '19px',
          }}>
            {type === 'good' ? 'イイネ！' : 'イマイチ'}
          </Typography>
        </Box>
        {type === 'good' ? (
          <ThumbUp sx={{ color: color, fontSize: '40px', marginTop: '8px' }} />
        ) : (
          <ThumbDown sx={{ color: color, fontSize: '40px', marginTop: '8px', transform: 'scale(-1, 1)' }} />
        )}

      </Paper>
    </ButtonBase>
  )
}

type dialogTypes = 'ask' | 'good' | 'bad'

export default function SwitchedFeedbackDialog() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { switched_feedback_dialog } = useSelector((state) => state.common);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const closeDialog = () => {
    dispatch(closeSwitchedFeedbackDialog());
    setDialogType('ask');
    setButtonDisabled(false)
  }
  const postFeedback = async (feedbackType: FeedbackType) => {
    if (!switched_feedback_dialog.scene_id) {
      return
    }
    await ApiService.postSceneFeedback(switched_feedback_dialog.scene_id, feedbackType);
    await dispatch(getUserDetail());
  }
  const navigateTo = useNavigateWithQueryAndHash();
  const [dialogType, setDialogType] = useState<dialogTypes>('ask')
  switch (dialogType) {
    case 'ask':
      return (
        <Dialog
          open={switched_feedback_dialog.open}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: '343px',
              width: '100%',
              margin: '16px',
              padding: '8px',
            },
            "& .MuiDialog-container": {
              sm: { marginLeft: `${drawerWidth}px` }
            },
          }}
        >
          <DialogTitle textAlign="center" sx={{ fontSize: '18px', whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id="scene.feedback.ask.title" />
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              gap: "16px",
              paddingBottom: 0,
            }}
          >
            <Stack direction="row" gap={2} justifyContent="center">
              <SpeechBubble type="good" disabled={buttonDisabled} onClick={async () => {
                setButtonDisabled(true)
                postFeedback('positive')
                setDialogType('good')
              }} />
              <SpeechBubble type="bad" disabled={buttonDisabled} onClick={async () => {
                setButtonDisabled(true)
                postFeedback('negative')
                setDialogType('bad')
              }} />
            </Stack>
            <Stack justifyContent="center">
              <Typography textAlign="center" fontSize="13px" color="black">
                <FormattedMessage id="scene.feedback.ask.description" />
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={async () => {
              await postFeedback('unanswered')
              closeDialog()
            }} size="small">閉じる</Button>
          </DialogActions>
        </Dialog>
      )
    case "good":
      return (
        <Dialog
          onClose={closeDialog}
          open={switched_feedback_dialog.open}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: '343px',
              width: '100%',
              margin: '16px',
              padding: '8px',
            },
            "& .MuiDialog-container": {
              sm: { marginLeft: `${drawerWidth}px` }
            },
          }}
        >
          <DialogTitle textAlign="center" sx={{ fontSize: '18px', whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id="scene.feedback.good.title" />
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              gap: "16px",
              padding: "8px",
            }}
          >
            <Paper elevation={0} sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: "2px solid",
              borderColor: mainTheme.palette.tertiary.main,
            }}>
              <Stack direction="row" gap={1} alignItems="center">
                <ControlPointDuplicate fontSize="medium" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                <Stack direction="row" gap={1} alignItems="baseline">
                  <Typography fontWeight={600} fontSize="20px">
                    400
                  </Typography>
                  <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="scene.feedback.good.minutes" values={{ minutes: 5 }} />
                  </Typography>
                </Stack>
              </Stack>
              <Typography fontWeight={700} fontSize="20px">
                $2.00
              </Typography>
            </Paper>
            <Button
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "click_value_switch_button",
                    user_id: user?.id,
                  }
                });
                navigateTo(backToMypagePath);
                closeDialog();
              }}
              fullWidth
              variant="contained"
              size="large"
              color="tertiary"
            >
              <FormattedMessage id="switch.dialog.button.limited_item_1" />（<FormattedMessage id="switch.dialog.button.limited_item_2" /> $7 ⇒$2）
            </Button>
          </DialogContent>
          <DialogTitle textAlign="center" sx={{ fontSize: '18px', marginTop: "8px", padding: '12px', whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id="scene.feedback.good.subtitle" />
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              gap: "16px",
              padding: "8px",
            }}
          >
            <Paper elevation={0} sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: "2px solid",
              borderColor: mainTheme.palette.primary.main,
            }}>
              <Stack direction="row" gap={1} alignItems="center">
                <Stack direction="row" gap={1} alignItems="baseline">
                  <Typography fontWeight={600} fontSize="20px">
                    Bronze
                  </Typography>
                  <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="user.plan.plan_suffix" />
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="baseline" gap={0.5}>
                <Typography fontWeight={700} fontSize="20px">
                  $15.00
                </Typography>
                <Typography fontWeight={700} fontSize="14px">
                  <FormattedMessage id="user.plan.per_month" />
                </Typography>
              </Stack>
            </Paper>
            <Button
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "move_plan_on_good_feedback",
                    user_id: user?.id,
                  }
                });
                navigateTo(backToPlanPagePath);
                closeDialog();
              }}
              fullWidth
              variant="contained"
              size="large"
              color="primary"
            >
              <FormattedMessage id="scene.feedback.good.plan_button" />
            </Button>
          </DialogContent>

          <DialogTitle textAlign="center" sx={{ fontSize: '18px' }}>
            <FormattedMessage id="user.plan.free.bronze_can" />
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              gap: "16px",
              padding: "8px",
            }}
          >
            <CanBronzePlan cardColor="rgba(242, 242, 242, 1)" />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} size="small">
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog >
      )
    case "bad":
      return (
        <Dialog
          onClose={closeDialog}
          open={switched_feedback_dialog.open}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: '343px',
              width: '100%',
              padding: '8px 16px',
            },
            "& .MuiDialog-container": {
              sm: { marginLeft: `${drawerWidth}px` }
            },
          }}
        >
          <DialogTitle textAlign="center" sx={{ fontSize: '18px' }}>
            <FormattedMessage id="scene.feedback.bad.title" />
          </DialogTitle>
          <DialogContent sx={{ padding: 0, overflow: 'visible' }}>
            <Stack
              direction="row"
              padding="14px"
              justifyContent="space-between"
              sx={{
                border: `2px solid ${color.main}`,
                borderRadius: "8px",
                backgroundColor: "rgba(247, 247, 247, 1)"
              }}
            >
              <img src={PresentBox} alt="present box" width="53px" />
              <Typography
                textAlign="center"
                fontWeight={400}
                fontSize="15px"
                whiteSpace="nowrap"
              >
                <FormattedMessage id="scene.feedback.bad.present1" /><br />
                <Typography fontWeight={500} fontSize="18px" color="primary" component='span'><FormattedMessage id="scene.feedback.bad.present2" /></Typography><FormattedMessage id="scene.feedback.bad.present3" /><br />
                <FormattedMessage id="scene.feedback.bad.present4" />
              </Typography>
            </Stack>
            <Stack justifyContent="center" gap={1} direction="row" marginTop={2}>
              <Typography fontSize="16px" color="primary">
                ＼
              </Typography>
              <Typography fontSize="16px" fontWeight={500}>
                <FormattedMessage id="scene.feedback.bad.tip_title" />
              </Typography>
              <Typography fontSize="16px" color="primary">
                ／
              </Typography>
            </Stack>
            <Stack gap={1} marginTop={1.5}>
              <TipBox title={<FormattedMessage id="scene.feedback.bad.tip_description1" />} tipNumber={1}>
                <img style={{ width: '255px' }} src="/plan/face_sample.png" alt="face sample" />
              </TipBox>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={closeDialog}>
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog >
      )
    default:
      return null
  }
}
