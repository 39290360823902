import { Link } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

import { closePlanDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { drawerWidth } from "@/constants";
import { FormattedMessage } from "react-intl";

export default function PlanDialog() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const closeDialog = () => {
    dispatch(closePlanDialog());
  };

  const { plan_dialog } = useSelector((state) => state.common);
  let text = <FormattedMessage id="plan_dialog.play" />;
  if (plan_dialog.use_type === 'swap') {
    text = <FormattedMessage id="plan_dialog.switch" />;
  } else if (plan_dialog.use_type === 'character') {
    text = <FormattedMessage id="plan_dialog.character" />;
  } else if (plan_dialog.use_type === 'favorite') {
    text = <FormattedMessage id="plan_dialog.favorite" />;
  }

  return (
    <Dialog
      onClose={closeDialog}
      open={plan_dialog.open}
      sx={{
        "& .MuiDialog-paper": {
          width: '100%',
        },
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          sm: { marginLeft: `${drawerWidth}px` }
        }
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '16px',
          lineHeight: 'normal',
          letterSpacing: '0.15px',
        }}
      >
        {text}<FormattedMessage id="plan_dialog.limit" />
      </DialogTitle>
      <DialogContent
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          paddingBottom: 0,
        }}
      >
        {user?.is_free_plan
          ? <Link to="/users/plan" onClick={closeDialog}>
            <Button fullWidth variant="contained"><FormattedMessage id="plan_dialog.upgrade" /></Button>
          </Link>
          : <Typography>
            追加でお気に入りする場合は既存のお気に入りを解除してください
          </Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}><FormattedMessage id="plan_dialog.close" /></Button>
      </DialogActions>
    </Dialog>
  );
}