import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import dayjs from 'dayjs';
import TagManager from "react-gtm-module"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ThemeProvider } from "@mui/material/styles";

import { RootLayout } from "@/layouts/RootLayout";
import { BaseLayout } from "@/layouts/BaseLayout";
import store from "@/services/store";
import { getUserInfo } from "@/services/actions/userActions";
import { mainTheme } from "@/services/themes";
import { useDispatch } from '@/services/hooks';
import { isAdult } from "@/constants";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { initTutorialStep } from "@/services/tutorial";
import * as Sentry from "@sentry/react";
import { getTempUserId, initTempUserId } from "@/services/demo";
import { GoogleOAuthProvider } from "@react-oauth/google";
export const sentryDSN = import.meta.env.VITE_SENTRY_DSN;

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.tz.setDefault('Asia/Tokyo'); // ブラウザの標準時で返すので不要

sentryDSN && Sentry.init({
  dsn: sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend: (event) => {
    const ignoreErrorMessages = [
      {
        type: 'AxiosError',
        value: 'Request failed with status code 401',
      },
    ] as const;
    const isIgnoreError = !!ignoreErrorMessages.find((message) => {
      return event.exception?.values?.find((value) => {
        return value.type === message.type && value.value?.includes(message.value)
      });
    });
    if (isIgnoreError) {
      console.log('Sentry ignore error', event);
      return null; // 送信しない
    }
    return event;
  },
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (import.meta.env.VITE_TAG_MANAGER_ID) {
  TagManager.initialize({
    gtmId: import.meta.env.VITE_TAG_MANAGER_ID
  });
  initTempUserId();
  TagManager.dataLayer({
    dataLayer: {
      temporary_user_id: getTempUserId() || 'none',
    }
  })
}

const AppInitializer: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserInfo()).then((me) => {
      if (me?.is_free_plan) {
        initTutorialStep();
      }
    })
  }, []);

  return null;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <AppInitializer />
        <BrowserRouter basename={isAdult ? "/" : "/normal"}>
          <RootLayout>
            <ThemeProvider theme={mainTheme}>
              <BaseLayout />
            </ThemeProvider>
          </RootLayout>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);
