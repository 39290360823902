// QAPage.tsx
import React from "react";

import {
  Stack,
  List,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";

const FAQPage: React.FC = () => {
  return (
    <AppBar title={<FormattedMessage id="faq.title" />}>
      <Stack padding={2}>
        <List>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((num, index) => (
            <Box key={index}>
              <ListItem
                sx={{
                  backgroundColor: "#FFF8F8",
                }}
              >
                <ListItemIcon>
                  <Avatar sx={{ color: "#21005D", backgroundColor: "#FFD9E2" }}>
                    Q
                  </Avatar>
                </ListItemIcon>
                <ListItemText sx={{ whiteSpace: 'pre-wrap' }}><FormattedMessage id={`faq.q_${num}`} /></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Avatar sx={{ color: "#21005D", backgroundColor: "#FFF" }}>
                    A
                  </Avatar>
                </ListItemIcon>
                <ListItemText sx={{ whiteSpace: 'pre-wrap' }} ><FormattedMessage id={`faq.a_${num}`} /></ListItemText>
              </ListItem>
            </Box>
          ))}
        </List>
      </Stack>
    </AppBar>
  );
};

export default FAQPage;
