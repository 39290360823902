import { openAlertModal, openSnackbar } from "@/services/actions/commonActions";
import { ApiService } from "@/services/api";
import { Box, IconButton, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "@/services/hooks";
import { Refresh } from "@mui/icons-material";
import { CheckGenerateStatusResponse } from "@/services/models";

type Swap = CheckGenerateStatusResponse['swaps'][number];

type ProgressBarProps = {
  checkGenerateStatus: () => Promise<void>;
  swap: Swap;
}

const generateText = (swap: Swap) => {
  if (swap.status === "in_progress" && swap.job_status === "IN_QUEUE") {
    return "generate.in_queue";
  }
  if (swap.status === "in_progress" && swap.job_status === "COMPLETED") {
    return "generate.in_uploading";
  }
  return "generate.in_progress";
}

const DotsAnimation = () => {
  // 3つのドットをアニメーションさせる
  const [dots, setDots] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((d) => (d + 1) % 4)
    }, 800)
    return () => clearInterval(interval)
  }, [])
  return (
    <Box width="13px">
      {".".repeat(dots)}
    </Box>
  )
}

export default function ProgressBar({
  checkGenerateStatus,
  swap,
}: ProgressBarProps) {
  const [disabledRegenerate, setDisabledRegenerate] = useState(false)
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  if (swap.status === 'completed') {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      borderTop="1px solid rgba(222, 222, 222, 1)"
      height="36px"
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ width: 'calc(100% - 119px)' }}>
        {swap.status === 'failed' ? (
          <LinearProgress variant="determinate" color="inherit" value={0} />
        ) : (
          <LinearProgress
            variant="determinate"
            value={swap.progress}
            color="primary"
            sx={{
              "&:after": {
                position: "absolute",
                content: "''",
                display: "inline-block",
                width: "100%",
                height: "100%",
                backgroundImage: "linear-gradient(60deg, transparent, transparent 30%, rgba(255, 255, 255, 70%) 50%, transparent 51%, transparent)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "300% 100%",
                animationName: "scroll-right",
                animationDuration: "2.8s",
                animationIterationCount: "infinite",
                animationTimingFunction: "cubic-bezier(0.22, 1, 0.36, 1)",
                animationDelay: "1s",
                animationFillMode: "backwards",
                "@keyframes scroll-right": {
                  "0%": { backgroundPosition: "100% 0" },
                  "50%": { backgroundPosition: "0 0" },
                  "100%": { backgroundPosition: "-100% 0" },
                }
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{ minWidth: '103px' }}
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        {swap.status === 'failed' ? (
          <>
            <FormattedMessage id="generate.failed" />
            <IconButton
              size="small"
              color="primary"
              sx={{ padding: 0 }}
              disabled={disabledRegenerate}
              onClick={async () => {
                if (disabledRegenerate) {
                  return;
                }
                setDisabledRegenerate(true)
                const data = await ApiService.regenerate(swap.id);
                const errorMessage = formatMessage({ id: `generate.error.${data.error_type}`, defaultMessage: "Error" });
                if (!data.success) {
                  setDisabledRegenerate(false)
                  // 特定のエラーの場合はモーダルで表示
                  if (data.error_type === 'over_parallel_limit') {
                    dispatch(openAlertModal(errorMessage));
                  } else {
                    dispatch(openSnackbar(errorMessage));
                  }
                  return;
                }
                await checkGenerateStatus();
                setDisabledRegenerate(false)
              }}
            >
              <Refresh fontSize="small" />
            </IconButton>
          </>
        ) : (
          <>
            <FormattedMessage id={generateText(swap)} />
            <DotsAnimation />
            {swap.job_status === "IN_QUEUE"
              || `${swap.status === 'not_started' ? 0 : swap.progress}%`}
          </>
        )}
      </Box>
    </Box>
  )
}