import React, { useEffect } from "react";
import dayjs from "dayjs";
import _ from 'lodash';

import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from 'react-router-dom';

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "@/services/hooks";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import { fetchNotices, readNotice } from "@/services/actions/commonActions";

const Notice: React.FC = () => {
  const dispatch = useDispatch();
  const { notices } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      dispatch(fetchNotices()).catch(() => {
        // notice error
      });
    }
  }, [user]);

  const onClickRead = () => {
    for (const notice of notices) {
      if (!notice.is_read) {
        dispatch(readNotice(String(notice.id))).catch(() => {
          console.log('read error', notice.id);
        })
      }
    }
  };


  return (
    <AppBar title={<FormattedMessage id="notice.title" />}>
      {
        !_.isEmpty(notices) && (
          <Box
            justifyContent="flex-end"
            display="flex"
            padding={2}
          >
            <IconButton
              onClick={onClickRead}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                width: 'fit-content',
                borderRadius: '8px',
              }}
            >
              <ChecklistRtlIcon />
              <Typography variant="caption" lineHeight={1}><FormattedMessage id="notice.all_check" /></Typography>
            </IconButton>
          </Box>
        )}
      <Divider />
      <List sx={{ padding: 0 }}>
        {
          _.isEmpty(notices) && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton>
                <ListItemText primary={<FormattedMessage id="notice.empty" />} />
              </ListItemButton>
            </ListItem>
          )
        }
        {
          notices.map(notice => {
            return (
              <ListItem
                disablePadding
                sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}>
                <Link to={`/notice/${notice.id}`} style={{ width: '100%', textDecoration: 'none' }}>
                  <ListItemButton>
                    <ListItemText
                      sx={{
                        paddingRight: '30px',
                      }}
                      primary={
                        <Typography
                          variant="body2"
                          sx={{
                            color: notice.is_read ? '#00000061' : '#000000DE',
                            fontWeight: notice.is_read ? 400 : 500,
                          }}
                        >
                          {notice.title}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="caption"
                          sx={{ color: notice.is_read ? '#00000061' : '#000000DE' }}
                        >
                          {dayjs(notice.start_date).format('YYYY/MM/DD HH:mm')}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="arrow-right">
                        <ArrowRightIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </Link>
              </ListItem>
            )
          })
        }
      </List>
    </AppBar>
  );
};

export default Notice;
