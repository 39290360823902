import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { backToPlanPagePath, cfCustomerCode } from "@/constants"; // cfCustomerCode をインポート
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "@/services/hooks.ts"; // GTMをインポート
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { incrementSwitchedScenesCount } from '@/services/actions/sceneActions';

interface LightStreamPlayerProps {
  src: string; // Cloudflare Streamのビデオソース
  scene_id?: number;
  video_uid?: string;
  is_tutorial?: boolean;
  thumbnail_start_sec?: number;
  is_limited?: boolean;
  scene_type?: string;
  filter?: string;
}

const LightStreamPlayer: React.FC<LightStreamPlayerProps> = ({ src, scene_id, video_uid, is_tutorial, scene_type, thumbnail_start_sec = 0, is_limited, filter }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [streamLoaded, setStreamLoaded] = useState(false);
  const [playClicked, setPlayClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const streamRef = useRef<StreamPlayerApi | undefined>();
  const { user } = useSelector((state) => state.user);
  const [startTime, setStartTime] = useState<number | undefined>()

  const [isPlayed, setIsPlayed] = useState(false);

  const imgSrc = `https://customer-${cfCustomerCode}.cloudflarestream.com/${src}/thumbnails/thumbnail.jpg?time=${thumbnail_start_sec}s`;

  useEffect(() => {
    const img = new Image();
    img.onload = () => setAspectRatio(img.naturalWidth / img.naturalHeight);
    img.src = imgSrc;
  }, [imgSrc]);

  const handlePlayClick = () => {
    if (is_limited) {
      return;
    }
    setPlayClicked(true);
    setIsLoading(true);
  };

  const handleVideoLoadStart = () => {
    setTimeout(() => {
      setIsLoading(false);
      setStreamLoaded(true);
      if (streamRef.current) {
        streamRef.current.play().then(() => {

        }).catch(() => {
          // play error
        });
      }
    }, 300);
  };

  const commonStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const iconContainerStyles = {
    ...commonStyles,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      paddingTop: `${(1 / aspectRatio) * 100}%`,
      '& > img, & > div': commonStyles,
    }}>
      {!streamLoaded && (
        <img
          src={imgSrc}
          alt="Description"
          style={{
            display: playClicked && !isLoading ? 'none' : 'block',
          }}
          onClick={handlePlayClick}
        />
      )}
      {!streamLoaded && !isLoading && !is_limited && (
        <Box sx={iconContainerStyles} onClick={handlePlayClick}>
          <PlayCircleOutlineIcon sx={{ fontSize: 64, color: 'white' }} />
        </Box>
      )}
      {isLoading && (
        <Box sx={iconContainerStyles}>
          <CircularProgress />
        </Box>
      )}
      {playClicked && (
        <Box sx={commonStyles}>
          <Stream
            streamRef={streamRef}
            controls
            src={src}
            width="100%"
            height="100%"
            autoplay
            onLoadStart={handleVideoLoadStart}
            onPlaying={() => {
              setStartTime(streamRef.current?.currentTime)
            }}
            onPlay={() => {
              setIsPlayed(true);
              if (!isPlayed) {
                // ビデオの再生が開始されたら、GTMイベントを送信
                TagManager.dataLayer({
                  dataLayer: {
                    event: "view_movie",
                    user_id: user?.id,
                    is_free_plan: user?.is_free_plan,
                    scene_id,
                    video_uid,
                    is_tutorial,
                    scene_type,
                    filter,
                  }
                });

                if (scene_id && scene_type === 'switched' && !is_tutorial) {
                  dispatch(incrementSwitchedScenesCount(scene_id));
                }
              }
            }}
            onPause={() => {
              const endTime = streamRef.current?.currentTime
              if (endTime === undefined || startTime === undefined) {
                return;
              }
              const startInt = Math.round(startTime)
              const endInt = Math.round(endTime)
              // ビデオの視聴時間のGTMイベントを送信
              TagManager.dataLayer({
                dataLayer: {
                  event: "stop_movie",
                  user_id: user?.id,
                  is_free_plan: user?.is_free_plan,
                  scene_id,
                  video_uid,
                  is_tutorial,
                  scene_type,
                  start_time: startInt,
                  end_time: endInt,
                  viewing_time: endInt - startInt,
                }
              });
              setStartTime(undefined)
            }}
          />
        </Box>
      )}
      {is_limited && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Stack
            display="flex"
            padding="0 16px"
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
              height: '74px',
              width: 'calc(100% - 32px)',
              position: 'absolute',
              top: 'calc(50% - 37px)',
              left: 0,
            }}
          >
            <Typography
              variant="subtitle2"
              color="white"
            >
              <FormattedMessage id="video_stream.upgrade.required" />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                navigateTo(backToPlanPagePath)
                TagManager.dataLayer({
                  dataLayer: {
                    event: "move_plan_from_video",
                    scene_id: scene_id,
                    user_id: user?.id,
                  }
                });
              }}
            >
              <FormattedMessage id="video_stream.upgrade.button" />
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default LightStreamPlayer;
