export const getGentamaCode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("fpc");
}

export const getMonetrackCode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("visitor_id");
}

export const isShowTags = () => {
  return import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging'
}
