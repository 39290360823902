import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { isAdult } from "@/constants";
import _ from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);

export const loadUTC = (date?: string): Dayjs => {
  if (!date) {
    return dayjs().utc().tz();
  }
  return dayjs.utc(date).tz();
}

export const getRelativeTime = (date: string): string => {
  const utcDate = loadUTC(date); // Etc/UTC をそのまま読み込み
  const now = dayjs().utc().tz(); // 現時刻を Etc/UTC で読み込み

  const secondsDiff = now.diff(utcDate, 'second');

  if (secondsDiff < 60) {
    return "Now";
  } else if (secondsDiff < 3600) {
    return `${Math.floor(secondsDiff / 60)} minutes ago`;
  } else if (secondsDiff < 86400) {
    return `${Math.floor(secondsDiff / 3600)} hours ago`;
  } else {
    return `${Math.floor(secondsDiff / 86400)} days ago`;
  }
};

const SESSION_KEY = 'face-switch';

const createSessionKey = (key: string) => {
  return `${SESSION_KEY}-${import.meta.env.MODE}-${key}`;
}

const isAvailableSession = () => {
  return ('localStorage' in window) && (window.localStorage !== null);
};

export const setSession = (key: string, value: string) => {
  if (isAvailableSession()) {
    window.localStorage.setItem(createSessionKey(key), value);
  }
}

export const getSession = (key: string) => {
  if (isAvailableSession()) {
    return window.localStorage.getItem(createSessionKey(key));
  }
  return null;
}

export const deleteSession = (key: string) => {
  if (isAvailableSession()) {
    window.localStorage.removeItem(createSessionKey(key));
  }
}

type ClickPaymentType = {
  hash_id: string,
  email: string,
  price: string,
  recur_id?: string,
  item_id?: number,
  back_path: string,
  temporary_id?: string,  // temporary_idがある場合は、サンクスページでASPの成果として送信されてしまうため、ASPのカウントをしない場合は指定しないこと
};

export const clickPayment = (props: ClickPaymentType) => {
  const { hash_id, email, price, recur_id, item_id, back_path, temporary_id } = props;
  const form = document.createElement('form');
  form.action = 'https://credit.inter-credit.link/purchase';
  form.method = 'POST';

  const queryParams = new URLSearchParams();
  temporary_id && queryParams.set('temporary_id', temporary_id);
  const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

  const list = [
    { name: 'mr_id', value: import.meta.env.VITE_MR_ID },
    { name: 'mr_pw', value: import.meta.env.VITE_MR_PW },
    { name: 'pg_id', value: import.meta.env.VITE_PG_ID },
    { name: 'pg_num', value: price },
    { name: 'user_id', value: hash_id },
    { name: 'email', value: email },
    // result_urlにクエリパラメータを指定しても除去されてしまうので、パスパラメータで値を渡す
    { name: 'tran_result_url', value: `${import.meta.env.VITE_API_ENDPOINT}/purchase/result` },
    { name: 'redirect_link_url', value: isAdult
      ? `${import.meta.env.VITE_API_ENDPOINT}/api/v1/purchase/adult/redirect${queryString}`
      : `${import.meta.env.VITE_API_ENDPOINT}/api/v1/purchase/normal/redirect${queryString}`
    },
    { name: 'back_url', value: isAdult
      ? `${import.meta.env.VITE_SITE_URL}${back_path}`
      : `${import.meta.env.VITE_SITE_URL}/normal${back_path}`
    },
  ]
  if (temporary_id) {
    list.push({ name: 'temporary_id', value: temporary_id });
  }

  if (recur_id) {
    list.push({ name: 'recur_id', value: recur_id });
    list.push({ name: 'recur_id_option', value: recur_id });
    list.push({ name: 'price_option', value: price });
  }

  if (item_id) {
    list.push({ name: 'item_id', value: item_id });
  }

  for (const item of list) {
    const q = document.createElement('input');
    q.type = 'hidden'
    q.value = item.value?.toString() || '';
    q.name = item.name;
    form.appendChild(q);
  }

  document.body.appendChild(form);
  form.submit();
}


export const getLocale = (): "ja" | "en" => {
  const language = ((window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language || 'en').slice(0, 2);

  if (language === 'ja') {
    return 'ja';
  }
  return 'en';
}

export const toSearchParams = (params: { [key: string]: any }): string => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      params[key].forEach((value: any) => searchParams.append(key, value));
    } else if (!_.isNull(params[key])) {
      searchParams.append(key, params[key]);
    }
  });

  return searchParams.toString();
}

export const isCrawler = () => {
  const userAgent = navigator.userAgent;
  const searchEngines = ['Googlebot', 'Bingbot', 'Yahoo! Slurp'];
  return searchEngines.some(bot => userAgent.includes(bot));
}

// 引き継ぎたくないクエリパラメータがある場合はここに追加する
const ignoreQuery: string[] = [
  'social_login'
];
// リファラル機能やASPの成果トラッキングのためのクエリパラメータやハッシュを引き継ぐときに使う
export const trackingUrlParam = () => {
  const queryParams = new URLSearchParams(window.location.search);
  ignoreQuery.forEach(name => {
    queryParams.delete(name)
  });
  const queryString = queryParams.toString();
  const hash = window.location.hash;  // リファラル機能のためにハッシュも引き継ぐ
  return queryString ? `?${queryString}${hash}` : hash;
}

export const resolveGenreParam = (base: string | null, name: string) => {
  let list = _.filter((base || '').split(','));
  if (list.indexOf(name) === -1) {
    list.push(name);
  } else {
    list = _.reject(list, (item) => {
      return item === name;
    })
  }
  return list.join(',');
}

// TODO: 決済復活キャンペーン終了したら消す
// 決済復活キャンペーン期間中かどうか
export const isLimitedCampaignPeriod = () => {
  return dayjs().isBefore(dayjs('2024-12-01T00:00:00Z'));
}
