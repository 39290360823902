import AppBar from "@/components/AppBar";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigateWithQueryAndHash } from "@/services/hooks";

const menu = [
  {
    text: <FormattedMessage id="sidebar.terms" />,
    icon: <EditOutlinedIcon color="primary" />,
    link: "/terms",
  },
  {
    text: <FormattedMessage id="sidebar.policy" />,
    icon: <PrivacyTipIcon color="primary" />,
    link: "/policy",
  },
  {
    text: <FormattedMessage id="sidebar.law" />,
    icon: <ReceiptLongIcon color="primary" />,
    link: "/law",
  },
];

const TermList: React.FC = () => {
  const navigateTo = useNavigateWithQueryAndHash();

  return (
    <AppBar title={<FormattedMessage id="term.title" />}>
      <Stack padding={2}>
        <List>
          {menu.map((item) => {
            return (
              <ListItem
                key={item.link}
                disablePadding
                sx={{
                  border: 'solid 1px #0000001F',
                  marginTop: '-1px',
                }}
                onClick={() => {
                  navigateTo(item.link);
                }}
              >
                <ListItemButton
                  sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                >
                  <Box display="flex" alignItems="center">
                    <ListItemIcon sx={{ minWidth: '40px' }}>{item.icon}</ListItemIcon>
                    <ListItemText sx={{ marginRight: '2px' }} primary={item.text} />
                  </Box>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Stack>
    </AppBar>
  );
};

export default TermList;