interface SceneState {
  unplayed_count: number,
}

const initialState: SceneState = {
  unplayed_count: 0,
};

export const sceneReducer = (state = initialState, action: any): SceneState => {
  switch (action.type) {
    case "INCREMENT_SWITCH_SCENE_COUNT_SUCCESS":
      return { ...state, unplayed_count: action.payload };
    case "GET_UNPLAYED_COUNT_SUCCESS": {
      return { ...state, unplayed_count: action.payload };
    }
    default:
      return state;
  }
};
