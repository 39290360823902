import { color } from "@/services/themes"
import { Box, Button } from "@mui/material"

type PropTypes = {
  tabType: 'scene' | 'image';
  setTabType: React.Dispatch<React.SetStateAction<"scene" | "image">>;
}

export default function SwitchTab(props: PropTypes) {
  const { tabType, setTabType } = props;
  return (
    <Box
      padding="24px 16px"
      display="flex"
      alignItems="center"
    >
      <Box
        width="50%"
        height="36px"
        sx={{
          background: 'rgb(224, 224, 224)',
          borderTopLeftRadius: '19px',
          borderBottomLeftRadius: '19px',
        }}
      >
        <Button
          sx={{
            boxShadow: 'none',
            width: '100%',
            height: '100%',
            borderRadius: '19px',
            color: tabType === 'scene' ? 'white' : `${color.main} !important`,
            background: tabType === 'scene' ? "primaary" : 'rgb(224, 224, 224) !important',
          }}
          variant="contained"
          onClick={() => { setTabType('scene') }}
        >
          動画
        </Button>
      </Box>
      <Box
        width="50%"
        height="36px"
        sx={{
          background: 'rgb(224, 224, 224)',
          borderTopRightRadius: '19px',
          borderBottomRightRadius: '19px',
        }}
      >
        <Button
          sx={{
            boxShadow: 'none',
            width: '100%',
            height: '100%',
            borderRadius: '19px',
            color: tabType === 'image' ? 'white' : `${color.main} !important`,
            background: tabType === 'image' ? "primaary" : 'rgb(224, 224, 224) !important',
          }}
          variant="contained"
          onClick={() => { setTabType('image') }}
        >
          おためし画像
        </Button>
      </Box>
    </Box>
  )
}