import { drawerWidth } from '@/constants';
import { useSelector } from '@/services/hooks';
import { loadUTC } from '@/utils';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent, TextField,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {useState} from "react";

type PropType = {
  onExecute: (body: string) => Promise<void>,
  onCancel: () => void,
  open: boolean,
}
export default function PlanDowngradeDialog(props: PropType) {
  const {
    onExecute, onCancel, open,
  } = props;
  const { detail } = useSelector((state) => state.user);
  const [body, setBody] = useState('')

  const isBodyError = body.length === 0;

  return (
    <>
      <Dialog
        onClose={onCancel}
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: '100%',
          },
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            sm: { marginLeft: `${drawerWidth}px` }
          }
        }}
      >
        <DialogTitle
          sx={{
            paddingTop: '24px',
            fontSize: '16px',
            lineHeight: 'normal',
            letterSpacing: '0.15px',
          }}
        >
          <Typography variant="subtitle1" textAlign="center">
            <FormattedMessage id="plan_downgrade.title" />
          </Typography>
        </DialogTitle>
        <DialogContent>

          <ul
            style={{
              paddingLeft: '16px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            <li>
              <Typography variant="body1">
                <FormattedMessage id="plan_downgrade.mes_01-1" />
                （<span style={{ color: '#F06292' }}>{loadUTC(detail?.paid_expired_at).format('YYYY/M/D')}</span>）
                <FormattedMessage id="plan_downgrade.mes_01-2" />
              </Typography>
            </li>
            <li><Typography variant="body1"><FormattedMessage id="plan_downgrade.mes_02" /></Typography></li>
            <li><Typography variant="body1"><FormattedMessage id="plan_downgrade.mes_03" /></Typography></li>
            <li><Typography variant="body1"><FormattedMessage id="plan_downgrade.mes_04" /></Typography></li>
            <li><Typography variant="body1"><FormattedMessage id="plan_downgrade.mes_05" /></Typography></li>
          </ul>

        </DialogContent>

        <Typography variant="body1" textAlign="center">
          <FormattedMessage id="plan_downgrade.tell_reason" />
        </Typography>
        <TextField
          style={{marginTop: '16px', marginLeft: '16px', marginRight: '16px'}}
          size="small"
          label={<FormattedMessage id="plan_downgrade.reason" />}
          variant="outlined"
          value={body}
          onChange={(e) => { setBody(e.target.value) }}
          error={isBodyError}
          required
          helperText={isBodyError ? <FormattedMessage id="plan_downgrade.please_enter" /> : ''}
          multiline
          rows={3}
        />

        <DialogActions sx={{ paddingBottom: '32px' }}>
          <Button color="inherit" onClick={async () => await onExecute(body)} disabled={isBodyError}><FormattedMessage id="plan_downgrade.yes" /></Button>
          <Button onClick={onCancel}><FormattedMessage id="plan_downgrade.cancel" /></Button>
        </DialogActions>
      </Dialog >
    </>
  );
}


