import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import {
  useDispatch,
  useSelector
} from '@/services/hooks';
import { FormattedMessage } from 'react-intl';
import Ribbon from '@/components/common/Ribbon';
import { closeLoginBonusDialog } from '@/services/actions/commonActions';
import { mainTheme } from "@/services/themes.ts";

const LoginBonusDialog = () => {
  const dispatch = useDispatch();
  const { login_bonus_dialog } = useSelector(state => state.common);
  const onClose = () => {
    dispatch(closeLoginBonusDialog());
  }
  return (
    <div>
      <Dialog
        open={login_bonus_dialog.open}
        onClose={onClose}
      >
        <DialogContent
          sx={{
            width: "320px",
            paddingBottom: 0,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <Ribbon>
              <Typography
                fontSize="20px"
                fontWeight={700}
                lineHeight="40px"
                color="#FFFFFF"
              >
                <FormattedMessage id="login_bonus.title" />
              </Typography>
            </Ribbon>
            <Box
              marginY="-8px"
            >
              <Typography
                fontSize="16px"
                fontWeight={700}
                color="#000"
              >
                <span style={{ color: mainTheme.palette.primary.main }}>＼ </span>
                <FormattedMessage id="login_bonus.limited" />
                <span style={{ color: mainTheme.palette.primary.main }}> ／</span>
              </Typography>
            </Box>

            <Typography
              fontSize="28px"
              fontWeight={700}
              color="primary"
            >
              <FormattedMessage id="login_bonus.title_1" />
            </Typography>

            <Box
              width="189px"
              height="42px"
              padding="4px 10px"
              borderRadius="10px"
              sx={{
                background: '#028ADB',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                fontSize="18px"
                fontWeight={700}
                color="#FFF176"
                component="span"
              >
                <FormattedMessage id="login_bonus.title_2_a" />
              </Typography>
              <Typography
                fontSize="24px"
                fontWeight={700}
                color="#FFF"
                component="span"
              >
                10
              </Typography>
              <Typography
                fontSize="18px"
                fontWeight={700}
                color="#FFF"
                component="span"
              >
                <FormattedMessage id="login_bonus.title_2_b" />
              </Typography>
              <Typography
                fontSize="18px"
                fontWeight={700}
                color="#FFF176"
                component="span"
              >
                <FormattedMessage id="login_bonus.title_2_c" />
              </Typography>
            </Box>

            <Typography
              variant="body2"
              textAlign="center"
            >
              <FormattedMessage id="login_bonus.text" />
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={onClose}
            >
              <FormattedMessage id="login_bonus.button" />
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >
            <FormattedMessage id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default LoginBonusDialog;
