import { setTutorialStep } from "@/services/actions/commonActions";
import { useDispatch } from "@/services/hooks";
import { TUTORIAL_STEP } from "@/services/tutorial";
import { Button, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

interface Props {
  open?: boolean;
  zIndex: number;
}

export default function Mask(props: Props) {
  const dispatch = useDispatch()
  const navigateTo = useNavigate()
  if (!props.open) {
    return null;
  }
  return (
    <>
      <div
        style={{
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          zIndex: props.zIndex,
          margin: 0,
        }}
      />

      <Stack
        position="fixed"
        marginBottom={3}
        padding={3}
        sx={{
          width: {
            xs: '100vw',
            sm: 'calc(100vw - 239px)',
          },
          right: 0,
          bottom: 0,
          zIndex: props.zIndex + 2,
        }}
      >
        <Button
          size="large"
          variant="contained"
          sx={{
            background: '#FFCA28',
            color: '#000000',
            '&:hover': {
              background: '#ab840c',
            }
          }}
          onClick={() => {
            dispatch(setTutorialStep(TUTORIAL_STEP.done));
            navigateTo('/')
          }}
        >
          <FormattedMessage id="hint.close_hint" />
        </Button>
      </Stack>
    </>
  )
}