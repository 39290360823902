import { axios } from '@/services/api';
import Axios from 'axios';
import { Dispatch } from 'redux';

export const incrementSwitchedScenesCount = (scene_id: number) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.put(`/api/v1/scenes/${scene_id}/play`, null, {
      withCredentials: true,
    });

    dispatch({ type: 'INCREMENT_SWITCH_SCENE_COUNT_SUCCESS', payload: data });
    return data;
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'INCREMENT_SWITCH_SCENE_COUNT_FAIL', payload: e.message });
    }
  }
};

export const getUnplayedSwitchedSceneCount = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get('/api/v1/scenes/unplayed_count', {
      withCredentials: true,
    });

    dispatch({ type: 'GET_UNPLAYED_COUNT_SUCCESS', payload: data });
    return data;
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'GET_UNPLAYED_COUNT_FAIL', payload: e.message });
    }
  }
};
